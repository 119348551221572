
import { useState } from 'react'
import PropTypes from 'prop-types'
import { animated } from '@react-spring/web'
import SwipeableViews from 'react-swipeable-views'
import { virtualize, bindKeyboard } from 'react-swipeable-views-utils'

import MediaItem from './MediaItem'
import { styles } from './FullPageStories.style'

import { convertTime } from 'lib/dates'

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews))

const FullPageStories = ({
    mediaList,
    isVisible,
    height,
    width,
    opacity,
}) => {
    const mediaLength = mediaList.length
    const [ activeMediaIdx, setActiveMediaIdx ] = useState(0)

    return (
        <animated.div
            style={{
                height: height,
                width: width,
                position: 'relative',
                overflow: 'hidden',
                borderRadius: 15,
                background: '#e7e9ed',
                zIndex: 3,
            }}
        >
            <div style={styles.topViewGradient}/>
            {mediaLength === 1
                ? null
                : <div
                    style={styles.stepperWrapper}
                    children={[...Array(mediaLength)].map((item, idx) => (
                        <div key={idx} style={{
                            ...styles.stepperItem,
                            ...(activeMediaIdx < idx ? { opacity: 0.66 } : {}),
                        }}
                        />
                    ))}
                />
            }
            <div
                style={styles.rightArea}
                onClick={() => {
                    if (activeMediaIdx + 1 === mediaLength) return
                    setActiveMediaIdx(activeMediaIdx + 1)
                }}
            />
            <animated.div
                style={{ ...styles.leftArea, opacity }}
                onClick={() => {
                    if (activeMediaIdx === 0) return
                    setActiveMediaIdx(activeMediaIdx - 1)
                }}
            />
            <animated.div style={{ ...styles.impressions, opacity }}>
                {mediaList[activeMediaIdx].impressions} Impressions
            </animated.div>
            <animated.div style={{ ...styles.reach, opacity }}>
                {mediaList[activeMediaIdx].reach} Reach
            </animated.div>
            <animated.div style={{ ...styles.timeStamp, opacity }}>
                {convertTime(mediaList[activeMediaIdx].date)}
            </animated.div>
            <VirtualizeSwipeableViews
                containerStyle={{
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    // willChange: 'height',
                }}
                animateTransitions={false}
                slideCount={mediaLength}
                index={activeMediaIdx}
                overscanSlideAfter={1}
                overscanSlideBefore={3}
                slideRenderer={({ key, index }) => (
                    <MediaItem
                        key={key}
                        data={mediaList[index]}
                        isVisible={activeMediaIdx === index && isVisible}
                        height={height}
                    />
                )}
            />
        </animated.div >
    )
}

FullPageStories.propTypes = {
    mediaList: PropTypes.array.isRequired,
    isVisible: PropTypes.bool,
    height: PropTypes.object,
    width: PropTypes.object,
    opacity: PropTypes.object,
}

FullPageStories.defaultProps = {
    isVisible: true,
    height: {},
    width: {},
    opacity: {},
}

MediaItem.propTypes = {
    data: PropTypes.object.isRequired,
    isVisible: PropTypes.bool.isRequired,
}

export default FullPageStories
