import PropTypes from 'prop-types'

import Image from 'components/Image'
import { convertNumber } from 'lib/numbers'
import Text from 'components/Text'
import Button from 'components/Button'

import bg from 'resources/images/profile-dashboard-bg.png'
import Iconly from 'components/Iconly'
import { useState } from 'react'

const styles = {
    outerWrapper: {
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
        paddingBottom: 30,
        borderRadius: 15,
        background: '#fff',
        overflow: 'hidden',
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginTop: -55,
        padding: '0px 30px',
        textAlign: 'center',
    },
    fans: {
        fontSize: 16,
        fontWeight: 500,
        color: '#69707E',
        lineHeight: '16px',
    },
    nameWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    cta: {
        height: 50,
        width: '100%',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
    },
    analyticsBtn: {
        padding: '10px 15px',
        backgroundColor: '#ffffff',
        border: '1px solid #EBF0F9',
        borderRadius: 10,
    },
    labelWrapper: {
        display: 'flex',
        color: '#2772FF',
        alignItems: 'center',
    },
    messageIcon: {
        fontSize: 24,
        marginRight: 10,
    },
}

const ProfileHeader = ({
    pic,
    name,
    fanbase,
    headline,
    country,
    onContact,
    bio,
    email,
    city,
}) => {
    const [ limit, setLimit ] = useState(70)
    const [ showEmail, setShowEmail ] = useState(false)

    const viewEmail = () => {
        setShowEmail(true)
    }

    return (
        <div style={styles.outerWrapper}>
            <img
                src={bg}
                alt={''}
                style={{
                    width: '100%',
                    // height: 100,
                    marginTop: -60,
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                }}
            />
            <div style={styles.wrapper}>
                <Image
                    src={pic}
                    width={100}
                    height={100}
                    objectFit={'cover'}
                    borderRadius={'100%'}
                />
                <div style={{ height: 15 }}/>
                <div style={styles.nameWrapper}>
                    <Text
                        children={name}
                        size={'h3'}
                        weight={'bold'}
                        color={'black'}
                        wrapperStyle={{ maxWidth: '100%' }}
                        truncated
                    />
                </div>
                {headline
                    ? (
                        <>
                            <div style={{ height: 20 }}/>
                            <div style={styles.labelWrapper}>
                                <Iconly
                                    type={'Work'}
                                    bold
                                />
                                <div style={{ width: 5 }}/>
                                <Text
                                    children={headline}
                                    size={'p'}
                                    color={'black'}
                                    wrapperStyle={{ maxWidth: '100%' }}
                                    truncated
                                />
                            </div>
                        </>
                    )
                    : null}
                {country && city
                    ? (
                        <>
                            <div style={{ height: 20 }}/>
                            <div style={styles.labelWrapper}>
                                <Iconly
                                    type={'Location'}
                                    bold
                                />
                                <div style={{ width: 5 }}/>
                                <Text
                                    children={`${country}, ${city}`}
                                    size={'p'}
                                    color={'black'}
                                    wrapperStyle={{ maxWidth: '100%' }}
                                    truncated
                                />
                            </div>
                        </>
                    )
                    : null}

                {fanbase
                    ? (
                        <>
                            <div style={{ height: 20 }}/>
                            <div style={styles.labelWrapper}>
                                <Iconly
                                    type={'Profile'}
                                    bold
                                />
                                <div style={{ width: 5 }}/>
                                <Text
                                    children={`${convertNumber(fanbase)} fans`}
                                    size={'p'}
                                    color={'black'}
                                    wrapperStyle={{ maxWidth: '100%' }}
                                    truncated
                                />
                            </div>
                        </>
                    )
                    : null}
                {bio
                    ? (
                        <>
                            <div style={{ height: 30 }}/>
                            <div style={styles.labelWrapper}>
                                <Text
                                    size={'p'}
                                    color={'text'}
                                    wrapperStyle={{ maxWidth: '100%', textAlign: 'left' }}
                                >
                                    {bio.slice(0, limit)}
                                    {bio.length > 70
                                        ? limit === 70
                                            ? <span onClick={() => setLimit(900)} style={{ fontWeight: 700 }}>...read more</span>
                                            : <span onClick={() => setLimit(70)} style={{ fontWeight: 700 }}> ...read less</span>
                                        : null
                                    }
                                </ Text>
                            </div>
                        </>
                    )
                    : null}
                <div style={{ height: 30 }}/>
                {showEmail
                    ? <Text
                        children={email}
                        size={'h6'}
                        color={'primary'}
                        weight={'semiBold'}
                        onClick={onContact}
                        wrapperStyle={{
                            width: '100%',
                            textAlign: 'center',
                            cursor: 'pointer',
                            height: 50,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    />
                    : (
                        <div style={{ display: 'flex', width: '100%', cursor: 'pointer' }}>
                            <Button
                                style={styles.cta}
                                onDisclose={viewEmail}
                            >
                                <div style={styles.messageIcon}>
                                    <Iconly type={'Message'}/>
                                </div>
                                <span>Contact Me</span>
                            </Button>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

ProfileHeader.propTypes = {
    pic: PropTypes.string,
    name: PropTypes.string,
    fanbase: PropTypes.number,
    headline: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    onContact: PropTypes.func,
    openAnalytics: PropTypes.func,
    bio: PropTypes.string,
    email: PropTypes.string,
}

ProfileHeader.defaultProps = {
    pic: null,
    name: null,
    fanbase: 0,
    headline: null,
    country: null,
    onContact: () => null,
    openAnalytics: () => null,
    bio: '',
    email: '',
    city: null,
}

export default ProfileHeader
