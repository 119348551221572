import PropTypes from 'prop-types'

import MetricCard from './MetricCard'
import MetricGrid from './MetricGrid'
import AnalyticsTitle from './AnalyticsTitle'
import ChartBarGrowth from './ChartBarGrowth'

import { convertNumber, makePercentage } from 'lib/numbers'

const FollowersGrowth = ({ count, growth, list }) => {
    return (
        <>
            <div style={{ height: 60 }}/>
            <AnalyticsTitle title={'Followers Overview'}/>
            <MetricGrid columns={3}>
                <MetricCard
                    icon={'Profile'}
                    title={'Followers'}
                    value={convertNumber(count)}
                />
                <MetricCard
                    icon={'Activity'}
                    title={'Follower Growth'}
                    value={convertNumber(growth)}
                />
                <MetricCard
                    icon={growth > 0 ? 'Arrow-Up' : 'Arrow-Down'}
                    iconBackground={growth > 0 ? '#E5F5EF' : '#FFF3F5'}
                    iconColor={growth > 0 ? '#00985F' : '#FF274B'}
                    title={'vs last 30d'}
                    value={makePercentage(growth / count)}
                />
            </MetricGrid>

            <div style={{ height: 15 }}/>
            <ChartBarGrowth data={list}/>
        </>
    )
}

FollowersGrowth.propTypes = {
    list: PropTypes.array,
    count: PropTypes.number,
    growth: PropTypes.number,
}

FollowersGrowth.defaultProps = {
    list: [],
    count: 0,
    growth: 0,
}

export default FollowersGrowth
