import PropTypes from 'prop-types'
import { useState, useCallback } from 'react'
import { useSpring } from '@react-spring/web'

import { convertDate } from 'lib/dates'
import Text from 'components/Text'
import ImageAlbum from 'components/ImageAlbum'
import Image from 'components/Image'

import Icon from 'components/Iconly'
import { convertNumber } from 'lib/numbers'

import BarChart from './ChartBar'
import Tabs from './Tabs'
import BoundingClientModal from 'components/BoundingClientModal'
import AnalyticsTitle from './AnalyticsTitle'

import FullPageStories from './FullPageStories'

const styles = {
    wrapper: {
        background: '#fff',
        // boxShadow: '0px 0px 10px rgba(205, 213, 231, 0.4)',
        // border: '1px solid #EBF0F9',
        paddingBottom: 15,
        paddingTop: 15,
        borderRadius: 15,
        boxShadow: '0px 0px 46.6167px rgba(205, 213, 231, 0.5)',
    },
    postsImageWrapper: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-around',
        paddingLeft: 43,
        paddingRight: 5,
        overflow: 'hidden',
    },
    imageWrapper: {
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
    },
    largePostsImgWrapper: {
        display: 'flex',
        paddingLeft: 15,
        overflowX: 'scroll',
        overflowY: 'hidden',
        scrollBehavior: 'smooth',
        WebkitOverflowScrolling: 'touch',
    },
    largeImg: {
        marginRight: 15,
        position: 'relative',
        cursor: 'pointer',
        transition: 'opacity, transform 0.2s',
    },
    title: {
        marginLeft: 15,
    },
    date: {
        fontSize: 12,
        fontWeight: 700,
        marginTop: 5,
        // transform: 'rotate(90deg)',
        width: 55,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        color: '#BCC6D9',
    },
    date2: {
        fontSize: 16,
        fontWeight: 600,
        marginTop: 15,
        // transform: 'rotate(90deg)',
        width: '100%',
        textAlign: 'center',
        color: '#BCC6D9',
    },
    contentItem: {
        position: 'absolute',
        zIndex: 1,
        background: 'rgba(17, 17, 24, 0.4)',
        backdropFilter: 'blur(10px)',
        borderRadius: 15,
        padding: '5px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentItemEngText: {
        color: '#FFFFFF',
        fontWeight: 600,
        fontSize: 14,
    },
    fullPageWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        height: '100%',
    },
    scrollWrapper: {
        position: 'relative',
    },
    scrollIndicator: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    scrollButton: {
        height: 50,
        width: 50,
        borderRadius: 50,
        background: 'linear-gradient(275.12deg, #04ECFD -46.59%, #2772FF 109.3%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        fontSize: 26,
        boxShadow: '0px 0px 30px rgba(205, 213, 231, 1)',
        cursor: 'pointer',
    },
}
const LastStories = ({ posts, tabs }) => {
    if (!posts || !posts.length) return null
    const [ activeTab, setActiveTab ] = useState(tabs[0] && tabs[0].key)
    const [ modalOpen, setModalOpen ] = useState(false)
    const [ targetPosts, setTargetPosts ] = useState([])
    const [ targetBounds, setTargetBounds ] = useState({
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        width: 0,
        height: 0,
    })

    const [ { opacity, height, width }, api2 ] = useSpring(() => ({
        opacity: 0,
        height: 0,
        width: 0,
        zIndex: 5,
    }))

    const onClickHandler = useCallback((event, item) => {
        setTargetPosts([...item.list].reverse())
        const boundingClient = event.target.getBoundingClientRect()
        setTargetBounds({
            width: boundingClient.width,
            top: boundingClient.top,
            bottom: boundingClient.bottom,
            right: boundingClient.right,
            left: boundingClient.left,
            height: boundingClient.height,
        })
        setTimeout(() => setModalOpen(true), 10)
        api2.start({
            opacity: 1,
            height: window.innerWidth * 1.66,
            width: window.innerWidth,
        })
    }, [])

    const closeModal = () => {
        api2.start({
            opacity: 0,
            height: targetBounds.height,
            width: targetBounds.width,
        })
        setTimeout(() => {
            setModalOpen(false)
            setTargetPosts([])
        }, 500)
    }

    return (
        <>
            <div style={{ height: 30 }}/>
            <div style={styles.wrapper}>
                <div style={styles.title}>
                    <AnalyticsTitle title={`Last ${posts.length} Stories`} />
                </div>
                <BoundingClientModal
                    close={closeModal}
                    targetBounds={targetBounds}
                    modalOpen={modalOpen}
                    itemPadding={15}
                    itemBorderRadius={15}
                    maxHeight={window.innerHeight}
                    maxWidth={window.innerWidth }
                    posts={targetPosts}
                    wrapperStyles={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 10,
                    }}
                >
                    <FullPageStories
                        isVisible={modalOpen}
                        mediaList={targetPosts}
                        opacity={opacity}
                        height={height}
                        width={width}
                    />
                </BoundingClientModal>
                <div style={styles.scrollWrapper}>
                    <div style={styles.largePostsImgWrapper}>
                        {posts.map((item, idx) => {
                            let hoverEff = null
                            return (
                                <div key={idx}>
                                    <div
                                        ref={e => (hoverEff = e)}
                                        style={styles.largeImg}
                                        onMouseEnter={() => {
                                            hoverEff.style.opacity = 0.8
                                            hoverEff.style.transform = 'scale(0.95)'
                                        }}
                                        onMouseLeave={() => {
                                            hoverEff.style.opacity = 1
                                            hoverEff.style.transform = 'scale(1)'
                                        }}
                                        onClick={(event) => onClickHandler(event, item)}
                                    >
                                        <div style={{
                                            ...styles.contentItem,
                                            bottom: 15,
                                            left: 15,
                                            zIndex: 4,
                                            display: item.impressions || item.views ? 'flex' : 'none',
                                        }}>
                                            <Icon
                                                type={'Show'}
                                                bold
                                                style={{
                                                    color: '#FFFFFF',
                                                    fontSize: 15,
                                                }}
                                            />
                                            <div style={{ width: 5 }}/>
                                            <div style={styles.contentItemEngText}>
                                                {convertNumber(item.reach)}
                                            </div>
                                        </div>
                                        <div style={{
                                            ...styles.contentItem,
                                            bottom: 15,
                                            right: 15,
                                            zIndex: 4,
                                            display: 'flex',
                                        }}>
                                            <Icon
                                                type={'Image'}
                                                bold
                                                style={{
                                                    color: '#FFFFFF',
                                                    fontSize: 15,
                                                }}
                                            />
                                            <div style={{ width: 5 }}/>
                                            <div style={styles.contentItemEngText}>
                                                {item.count}
                                            </div>
                                        </div>
                                        <ImageAlbum
                                            width={window.innerWidth * 0.45}
                                            height={(window.innerWidth * 0.45) / 9 * 16}
                                            src={item.list[item.list.length - 1].pic}
                                            borderRadius={15}
                                            fallbackType={'storyFallback'}
                                            objectFit={'cover'}
                                        />
                                    </div>
                                    <div
                                        style={styles.date2}
                                        children={convertDate(item.day)}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div style={{ height: 40 }}/>
                <Tabs
                    tabs={tabs}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                />
                <div style={{ height: 30 }}/>
                <BarChart data={posts} dataKey={activeTab} hideDate/>
                <div style={styles.postsImageWrapper}>
                    {[...posts].reverse().map((item, idx) => {
                        let hoverEff1 = null
                        return (
                            <div
                                key={idx}
                                ref={e => (hoverEff1 = e)}
                                onMouseEnter={() => {
                                    hoverEff1.style.opacity = 0.8
                                    hoverEff1.style.transform = 'scale(0.95)'
                                }}
                                onMouseLeave={() => {
                                    hoverEff1.style.opacity = 1
                                    hoverEff1.style.transform = 'scale(1)'
                                }}
                                onClick={(event) => onClickHandler(event, item)}
                                style={{
                                    ...styles.imageWrapper,
                                }}>
                                <Image
                                    height={51.55}
                                    width={29}
                                    src={item.list[0].pic}
                                    borderRadius={5}
                                    objectFit={'cover'}
                                    fallbackType={'contentV1'}
                                />
                                <Text
                                    size={'p2'}
                                    color={'grey'}
                                    weight={'medium'}
                                    children={convertDate(item.day)}
                                    wrapperStyle={{ marginTop: 20, transform: 'rotate(90deg)' }}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

LastStories.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.shape({
        views: PropTypes.number,
        comments: PropTypes.number,
        likes: PropTypes.number,
    })),
    tabs: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
    })),
}

LastStories.defaultProps = {
    posts: [],
    tabs: [],
}

export default LastStories
