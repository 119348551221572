import PropTypes from 'prop-types'
import { useState } from 'react'

import { colors } from 'styles'

import Skeleton from 'components/Skeleton'

const styles = {
    wrapper: {
        position: 'relative',
    },
    albumCover: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 3,
    },
}
const failedImages = {}
const loadedImages = {}

const fallbacks = {
    profile: 'https://generalcdn.mysocial.io/fallback-profile-v2.jpeg',
    contentV1: 'https://generalcdn.mysocial.io/broken-image-v1.png',
    storyFallback: 'https://generalcdn.mysocial.io/broken-image-story-v1.png',
}

const ImageAlbum = ({ src, width, height, borderRadius, border, objectFit, loaderColor, onClick, fallbackType, fallbackSrc }) => {
    const [ loaded, setLoaded ] = useState(loadedImages[src])
    const fallback = fallbacks[fallbackType] ? fallbacks[fallbackType] : fallbackSrc
    const source = failedImages[src] ? fallback : src

    return (
        <div style={{
            width,
            height: height + 20,
            position: 'relative',
        }}>
            <div style={styles.albumCover}>
                {!loaded
                    ? (
                        <Skeleton
                            style={{
                                borderRadius,
                                width,
                                height,
                                ...(loaderColor ? { backgroundColor: colors[loaderColor] } : {}),
                            }}
                        />
                    )
                    : null}
                <div
                    style={{
                        display: loaded ? 'block' : 'none',
                        width,
                        height,
                        backgroundColor: '#F4F4F4',
                        borderRadius,
                    }}
                >
                    <img
                        src={source}
                        alt={'image'}
                        onError={() => {
                            failedImages[src] = true
                            !loaded && setLoaded(true)
                            loadedImages[src] = true
                        }}
                        onLoad={() => {
                            !loaded && setLoaded(true)
                            loadedImages[src] = true
                        }}
                        style={{
                            width,
                            height,
                            minWidth: width,
                            maxWidth: width,
                            minHeight: height,
                            maxHeight: height,
                            borderRadius,
                            border,
                            ...(objectFit ? { objectFit } : {}),
                        }}
                        onClick={onClick}
                    />
                </div>
            </div>
            <div
                style={{
                    // width,
                    // height,
                    backgroundColor: '#F6F9FF',
                    borderRadius,
                    position: 'absolute',
                    top: 0,
                    right: 30,
                    left: 30,
                    bottom: 0,
                    zIndex: 1,
                }}
            />
            <div
                style={{
                    // width,
                    // height,
                    backgroundColor: '#EDF3FF',
                    borderRadius,
                    position: 'absolute',
                    top: 10,
                    right: 15,
                    left: 15,
                    bottom: 0,
                    zIndex: 2,
                }}
            />
        </div>
    )
}

ImageAlbum.propTypes = {
    src: PropTypes.string,
    onClick: PropTypes.func,
    fallbackType: PropTypes.string,
    fallbackSrc: PropTypes.string,
    loaderColor: PropTypes.string,

    objectFit: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    borderRadius: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    border: PropTypes.string,
}

ImageAlbum.defaultProps = {
    src: '',
    fallbackType: 'profile',
    fallbackSrc: fallbacks.profile,
    onClick: () => null,
    width: 50,
    height: 50,
    borderRadius: '100%',
    objectFit: null,
    loaderColor: null,
    border: null,
}

export default ImageAlbum
