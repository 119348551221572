
import PropTypes from 'prop-types'
import { BarChart } from 'recharts/lib/chart/BarChart'
import { Bar } from 'recharts/lib/cartesian/Bar'
import { XAxis } from 'recharts/lib/cartesian/XAxis'
import { YAxis } from 'recharts/lib/cartesian/YAxis'
import { CartesianGrid } from 'recharts/lib/cartesian/CartesianGrid'
import { Tooltip } from 'recharts/lib/component/Tooltip'
import { ResponsiveContainer } from 'recharts/lib/component/ResponsiveContainer'

import { convertNumber } from 'lib/numbers'
import { convertDate } from 'lib/dates'

import { chartConvertNumber } from './lib/chart-numbers'

const styles = {
    tooltipContent: {
        border: 'none',
        margin: 0,
        padding: '0 10px',
        borderRadius: 20,
        backgroundColor: '#fff',
        boxShadow: '0px -4px 20px rgba(205, 213, 231, 0.25)',
    },
    tooltipItem: {
        fontSize: 18,
        fontWeight: 700,
        display: 'inline-block',
        color: '#2772FF',
    },
    tooltipLabel: {
        fontSize: 18,
        color: '#BCC6D9',
    },
    tick: {
        fill: '#BCC6D9',
        fontSize: 12,
    },
    negativeColor: '#FFD9DF',
    positiveColor: '#DAE7FF',
}

const ChartBarGrowth = ({ data }) => {
    if (!data.length) return null

    const chartData = data
        .map(i => ({
            date: i.date,
            gain: i.value > 0 ? i.value : null,
            loss: i.value < 0 ? i.value : null,
        }))

    return (
        <ResponsiveContainer height={250} width={'100%'}>
            <BarChart data={chartData}>
                <XAxis
                    dataKey={'date'}
                    tickFormatter={(value) => convertDate(value)}
                    axisLine={false}
                    tickLine={false}
                    tick={styles.tick}
                    height={40}
                    interval={2}
                    tickMargin={20}
                    angle={-90}
                    tickCount={5}
                />
                <YAxis
                    width={30}
                    tickFormatter={(value) => chartConvertNumber(value)}
                    axisLine={false}
                    tickLine={false}
                    tick={styles.tick}
                    tickCount={6}
                />
                <CartesianGrid
                    vertical={false}
                    strokeDasharray={'6'}
                    stroke={'#EBF0F9'}
                    strokeWidth={1}
                />
                <Tooltip
                    offset={60}
                    separator={''}
                    contentStyle={styles.tooltipContent}
                    cursor={{ fill: '#EBF0F9' }}
                    itemStyle={styles.tooltipItem}
                    labelStyle={styles.tooltipLabel}
                    formatter={(value, name, props) => {
                        if (value === null) return null
                        return convertNumber(value, 2)
                    }}
                    labelFormatter={(value) => ''}
                />
                <Bar
                    dataKey={'gain'}
                    fill={styles.positiveColor}
                    stackId={'stack'}
                    name={' '}
                    radius={[ 10, 10, 0, 0 ]}
                />
                <Bar
                    dataKey={'loss'}
                    fill={styles.negativeColor}
                    stackId={'stack'}
                    name={' '}
                    radius={[ 10, 10, 0, 0 ]}
                />
            </BarChart>
        </ResponsiveContainer>
    )
}

ChartBarGrowth.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        data: PropTypes.string,
        value: PropTypes.number,
    })),
}

ChartBarGrowth.defaultProps = {
    data: [],
}

export default ChartBarGrowth
