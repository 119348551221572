
export const chartConvertNumber = (number) => {
    if (!number) return 0

    const thousand = 1000
    const million = 1000000

    if (number >= 1000000 || number <= -1000000) {
        return `${(number / million).toFixed(1)}M`
    }

    if (number >= thousand || number <= -million) {
        return `${(number / thousand).toFixed(0)}K`
    }

    return Number(number).toFixed(0)
}
