
import YouTubeOverview from '../components/YouTubeOverview'
import PropTypes from 'prop-types'
import LastPosts from '../components/LastPosts'
import YouTubeAudience from '../components/YouTubeAudience'
import FollowersGrowth from '../components/FollowersGrowth'
import YouTubeSubsGrowth from '../components/YouTubeSubsGrowth'
import ProfileOverview from '../components/ProfileOverview'
import Loader from '../components/Loader'
import NoAnalytics from '../components/NoAnalytics'

const Youtube = ({ data }) => {
    if (data === undefined) return <Loader />
    if (data === null) return <NoAnalytics vendor={'YouTube'} />

    const postsProps = {
        posts: data.postsList.slice(0, 10).reverse(),
        tabs: [
            { key: 'views', label: 'Views' },
            { key: 'interactions', label: 'Interactions' },
            data.needAuth ? null : { key: 'retention', label: 'Retention' },
            data.needAuth ? null : { key: 'subGain', label: 'Subs Gained' },
            data.needAuth ? null : { key: 'subLost', label: 'Subs Lost' },
            data.needAuth ? null : { key: 'minWatched', label: 'Min Watched' },
        ].filter(i => i !== null),
    }

    const audienceProps = {
        devices: data.devices,
        countries: data.countries,
        sources: data.sources,
        genders: data.genders,
        ages: data.ages,
        sourcesOS: data.sourcesOS,
    }

    const followersProps = {
        growth: data.needAuth ? null : data.current30.reduce((acc, curr) => (acc || 0) + curr.subGain - curr.subLost, 0),
        data: data.needAuth
            ? null
            : data.current30.map(i => ({ date: i.date, gain: i.subGain, lost: -i.subLost })),
        totalGained: data.needAuth ? null : data.current30.reduce((acc, curr) => (acc || 0) + curr.subGain, 0),
        totalLost: data.needAuth ? null : data.current30.reduce((acc, curr) => (acc || 0) + curr.subLost, 0),
        count: data.followers,
        vendor: 'youtube',
    }

    const altFollowersProps = {
        growth: data.followersGrowth,
        count: data.followers,
        data: data.followersGrowthList,
        vendor: 'youtube',
    }

    const interactionsProps = {
        tabs: [
            { key: 'views', label: 'Views' },
            { key: 'likes', label: 'Likes' },
            { key: 'minWatched', label: 'Min Watched' },
            { key: 'comments', label: 'Comments' },
        ],
        tabsData: {
            views: {
                count: data.needAuth ? 0 : data.current30.reduce((acc, curr) => (acc || 0) + curr.views, 0),
                lastCount: data.needAuth ? 0 : data.last30.reduce((acc, curr) => (acc || 0) + curr.views, 0),
            },
            likes: {
                count: data.needAuth ? 0 : data.current30.reduce((acc, curr) => (acc || 0) + curr.likes, 0),
                lastCount: data.needAuth ? 0 : data.last30.reduce((acc, curr) => (acc || 0) + curr.likes, 0),
            },
            minWatched: {
                count: data.needAuth ? 0 : data.current30.reduce((acc, curr) => (acc || 0) + curr.minWatched, 0),
                lastCount: data.needAuth ? 0 : data.last30.reduce((acc, curr) => (acc || 0) + curr.minWatched, 0),
            },
            comments: {
                count: data.needAuth ? 0 : data.current30.reduce((acc, curr) => (acc || 0) + curr.comments, 0),
                lastCount: data.needAuth ? 0 : data.last30.reduce((acc, curr) => (acc || 0) + curr.comments, 0),
            },
        },
        data: data.needAuth ? [] : data.current30,
    }

    return (
        <div style={{ padding: 15, overflow: 'visible' }}>
            <div style={{ height: 15 }}/>
            <YouTubeOverview
                views={data.views}
                interactions={data.interactions}
                followers={data.followers}
            />
            {!postsProps.posts.length ? null : <div style={{ height: 30 }}/>}
            <LastPosts
                {...postsProps}
                vendor={'youtube'}
                openUrl={`https://youtube.com/channel/${data.id}`}
            />
            {data.needAuth
                ? null
                : <YouTubeAudience
                    {...audienceProps}
                /> }

            <div style={{ height: 30 }}/>
            {data.needAuth
                ? <FollowersGrowth {...altFollowersProps} />
                : <YouTubeSubsGrowth {...followersProps} />
            }
            {data.needAuth ? null : <div style={{ height: 30 }}/> }
            {data.needAuth ? null : <ProfileOverview {...interactionsProps}/>}
        </div>
    )
}

Youtube.propTypes = {
    data: PropTypes.object,
}

Youtube.defaultProps = {
    data: undefined,
}

export default Youtube
