import PropTypes from 'prop-types'

import MetricCard from './MetricCard'
import MetricGrid from './MetricGrid'
import AnalyticsTitle from './AnalyticsTitle'
import ChartBar2Value from './ChartBar2Value'

import { convertNumber, makePercentage } from 'lib/numbers'

const YouTubeSubsGrowth = ({ count, growth, data, totalGained, totalLost }) => {
    return (
        <>
            <div style={{ height: 60 }}/>
            <AnalyticsTitle title={'Subscribers Overview'}/>
            <MetricGrid columns={2}>
                <MetricCard
                    icon={'Show'}
                    title={'Subscribers'}
                    value={convertNumber(count)}
                />
                <MetricCard
                    icon={'Activity'}
                    title={'vs last 30d'}
                    value={makePercentage(growth / count)}
                />
                <MetricCard
                    icon={'Arrow-Up'}
                    iconBackground={'#E5F5EF'}
                    iconColor={'#00985F'}
                    title={'Subs Gained'}
                    value={convertNumber(totalGained)}
                />
                <MetricCard
                    title={'Subs Lost'}
                    icon={'Arrow-Down'}
                    iconBackground={'#FFF3F5'}
                    iconColor={'#FF274B'}
                    value={convertNumber(-totalLost)}
                />
            </MetricGrid>

            <div style={{ height: 15 }}/>
            <ChartBar2Value data={data}/>
        </>
    )
}

YouTubeSubsGrowth.propTypes = {
    data: PropTypes.array,
    count: PropTypes.number,
    totalGained: PropTypes.number,
    totalLost: PropTypes.number,
    growth: PropTypes.number,
}

YouTubeSubsGrowth.defaultProps = {
    data: [],
    count: 0,
    growth: 0,
    totalGained: 0,
    totalLost: 0,
}

export default YouTubeSubsGrowth
