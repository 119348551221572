import PropTypes from 'prop-types'
import { animated } from '@react-spring/web'

import { convertNumber } from 'lib/numbers'

import Image from 'components/Image'
import Text from 'components/Text'

const styles = {
    wrapper: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    gridMetrics: {
        marginTop: -5,
    },
    metricWrapper: {
        background: '#fff',
        width: '50%',
        display: 'inline-block',
        textAlign: 'center',
        paddingTop: 20,
        paddingBottom: 20,
    },
}

const FullPagePost = ({ animatedProps, tabs, targetPost, vendor }) => {
    return (
        <div style={{
            ...styles.wrapper,
            alignItems: 'center',
            position: 'relative',
            zIndex: 3,
        }}>
            <div style={{ background: '#fff', borderRadius: 5, overflow: 'scroll', WebkitOverflowScrolling: 'touch' }}>
                <Image
                    height={'100%'}
                    width={'100%'}
                    src={targetPost.pic}
                    borderRadius={0}
                    fallbackType={
                        vendor === 'tiktok'
                            ? 'storyFallback'
                            : vendor === 'instagram'
                                ? 'squareFallback'
                                : 'contentV1'
                    }
                />
                <animated.div
                    style={{
                        ...styles.gridMetrics,
                        ...animatedProps,
                    }}
                >
                    {tabs.map((tab, idx) => {
                        return (
                            <div style={styles.metricWrapper} key={idx}>
                                <Text
                                    size={'h2'}
                                    weight={'bold'}
                                    children={convertNumber(targetPost[tab.key])}
                                />
                                <Text
                                    color={'text'}
                                    children={tab.label}
                                />
                            </div>
                        )
                    })}
                </animated.div>
            </div>
        </div>
    )
}

FullPagePost.propTypes = {
    animatedProps: PropTypes.object.isRequired,
    tabs: PropTypes.array.isRequired,
    targetPost: PropTypes.object.isRequired,
    vendor: PropTypes.string.isRequired,
}

export default FullPagePost
