
import * as service from './profile.service'
import reducer from './profile.reducer'

export const reducers = {
    profile: reducer,
}
export const services = []
export const listeners = []

export const getProfileData = service.getProfileData
export const getAnalytics = service.getAnalytics
