
import Spinner from 'components/Spinner'

const styles = {
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30,
    },
}

const Loader = () => {
    return (
        <div style={styles.wrapper}>
            <Spinner
                size={60}
                disableShrink
            />
        </div>
    )
}

export default Loader
