import PropTypes from 'prop-types'
import { useState } from 'react'

import Text from 'components/Text'
import Bar from './Bar'
import Tabs from './Tabs2'

const styles = {
    wrapper: {
        padding: 40,
        background: '#fff',
        borderRadius: 15,
        boxShadow: '0px 0px 46.6167px rgba(205, 213, 231, 0.5)',
    },
}

const YouTubeAgeCard = ({ title, data }) => {
    const tabs = [
        { key: 'total', label: 'All' },
        { key: 'male', label: 'Male' },
        { key: 'female', label: 'Female' },
    ]

    const [ activeTab, setActiveTab ] = useState(tabs[0] && tabs[0].key)
    const modifiedData = data.sort((a, b) => (a.total > b.total ? -1 : 1))

    return (
        <div style={styles.wrapper}>
            <Text
                children={title}
                size={'h5'}
                weight={'semiBold'}
            />
            <div style={{ height: 15 }}/>
            <Tabs
                tabs={tabs}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                justifyContent={'flex-start'}
            />
            {modifiedData.slice(0, 5).map((i, idx) => {
                return (
                    <Bar
                        key={idx}
                        name={i.type}
                        perc={i[activeTab] / 100}
                        value={i[activeTab]}
                        showPercent
                    />
                )
            })}
        </div>
    )
}

YouTubeAgeCard.propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
}

export default YouTubeAgeCard
