
import PropTypes from 'prop-types'

import { InstagramIcon, TiktokIcon, YoutubeIcon } from 'components/Icons'
// import { convertNumber } from 'lib/numbers'
import { capitalizeString } from 'lib/strings'

import Text from 'components/Text'

const styles = {
    wrapper: {
        position: 'sticky',
        top: 50,
        left: 0,
        right: 0,
        zIndex: 5,
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    channelsWrapper: {
        display: 'flex',
        overflow: 'scroll',
        scrollBehavior: 'smooth',
        WebkitOverflowScrolling: 'touch',
    },
    channelItem: {
        padding: '15px 15px',
        display: 'flex',
        alignItems: 'center',
    },
}

const getVendorIcon = (vendor) => {
    switch (vendor) {
        case 'instagram': return <InstagramIcon size={20}/>
        case 'youtube': return <YoutubeIcon size={20}/>
        case 'tiktok': return <TiktokIcon size={20}/>
        default: return null
    }
}

const ChannelsCarousel = ({ channels, activeChannel, setActiveChannel }) => {
    const htmlElement = document.getElementById('container')

    const onSelectChannel = (i, id) => {
        const itemPosition = document.getElementById(id).getBoundingClientRect()
        setActiveChannel(i)
        htmlElement.scrollLeft += itemPosition.left - 80
    }

    return (
        <>
            <div style={styles.wrapper}>
                <div style={styles.channelsWrapper} id={'container'}>
                    {channels.map((i, idx) => (
                        <div
                            key={i.id}
                            id={`${i.id}+${idx}`}
                            style={{
                                ...styles.channelItem,
                                borderBottom: activeChannel.id === i.id ? '2px solid #2772FF' : '2px solid #EBF0F9',
                            }}
                            onClick={() => onSelectChannel(i, `${i.id}+${idx}`)}
                        >
                            {getVendorIcon(i.vendor)}
                            <div style={{ width: 10 }}/>
                            <Text
                                children={`${capitalizeString(i.username)} `}
                                color={activeChannel.id === i.id ? 'primary' : 'grey2'}
                                weight={activeChannel.id === i.id ? 'bold' : 'medium'}
                                size={'h6'}
                                wrapperStyle={{ whiteSpace: 'nowrap' }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

ChannelsCarousel.propTypes = {
    channels: PropTypes.array,
    activeChannel: PropTypes.object,
    setActiveChannel: PropTypes.func,
}

ChannelsCarousel.defaultProps = {
    channels: [],
    activeChannel: {},
    setActiveChannel: () => null,
}

export default ChannelsCarousel
