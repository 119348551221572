
import PropTypes from 'prop-types'

import Youtube from './Youtube'
import Instagram from './Instagram'
import Tiktok from './Tiktok'

const Analytics = ({ data, vendor }) => {
    switch (vendor) {
        case 'youtube': return <Youtube data={data}/>
        case 'tiktok': return <Tiktok data={data}/>
        case 'instagram': return <Instagram data={data}/>
        default: return 'unknown channel'
    }
}

Analytics.propTypes = {
    data: PropTypes.object,
    vendor: PropTypes.string,
}

Analytics.defaultProps = {
    data: undefined,
    vendor: 'youtube',
}

export default Analytics
