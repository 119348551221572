
import { useState } from 'react'
import PropTypes from 'prop-types'
import { animated } from '@react-spring/web'
import SwipeableViews from 'react-swipeable-views'
import { virtualize, bindKeyboard } from 'react-swipeable-views-utils'

import MediaItem from './MediaItem'
import { styles } from './FullPageStories.style'

import { convertTime } from 'lib/dates'

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews))

const FullPageStories = ({
    mediaList,
    isVisible,
    opacity,
    height,
    width,
}) => {
    const mediaLength = mediaList.length
    const [ activeMediaIdx, setActiveMediaIdx ] = useState(0)

    return (
        <animated.div
            style={{
                width,
                height,
                position: 'relative',
                overflow: 'hidden',
                borderRadius: 15,
                willChange: 'height,width',
                zIndex: 3,
            }}
        >
            <div style={styles.topViewGradient}/>
            {mediaLength === 1
                ? null
                : <div
                    style={styles.stepperWrapper}
                    children={[...Array(mediaLength)].map((item, idx) => (
                        <div key={idx} style={{
                            ...styles.stepperItem,
                            ...(activeMediaIdx < idx ? { opacity: 0.66 } : {}),
                        }}
                        />
                    ))}
                />
            }
            <div
                style={styles.rightArea}
                onClick={() => {
                    if (activeMediaIdx + 1 === mediaLength) return
                    setActiveMediaIdx(activeMediaIdx + 1)
                }}
            />
            <div
                style={styles.leftArea}
                onClick={() => {
                    if (activeMediaIdx === 0) return
                    setActiveMediaIdx(activeMediaIdx - 1)
                }}
            />
            <animated.div
                style={{ opacity }}>
                <div style={styles.impressions}>
                    {mediaList[activeMediaIdx].impressions} Impressions
                </div>
                {mediaList[activeMediaIdx].impressions} Impressions
            </animated.div>
            <animated.div
                style={{ opacity }}>
                <div style={styles.reach}>
                    {mediaList[activeMediaIdx].reach} Reach
                </div>
            </animated.div>
            <animated.div style={{ opacity }}>
                <div style={styles.timeStamp}>
                    {convertTime(mediaList[activeMediaIdx].date)}
                </div>
            </animated.div>
            <VirtualizeSwipeableViews
                containerStyle={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    // willChange: 'height',
                    height: '100%',
                }}
                style={{ height: '100%' }}
                animateTransitions={false}
                slideCount={mediaLength}
                index={activeMediaIdx}
                overscanSlideAfter={1}
                overscanSlideBefore={3}
                slideRenderer={({ key, index }) => (
                    <MediaItem
                        key={key}
                        // width={width}
                        // height={height}
                        data={mediaList[index]}
                        isVisible={activeMediaIdx === index && isVisible}
                    />
                )}
            />
        </animated.div >
    )
}

FullPageStories.propTypes = {
    mediaList: PropTypes.array.isRequired,
    isVisible: PropTypes.bool,
    opacity: PropTypes.object,
    height: PropTypes.object,
    width: PropTypes.object,
}

FullPageStories.defaultProps = {
    isVisible: true,
    opacity: {},
    width: {},
    height: {},
}

MediaItem.propTypes = {
    data: PropTypes.object.isRequired,
    isVisible: PropTypes.bool.isRequired,
}

export default FullPageStories
