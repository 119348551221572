
import PropTypes from 'prop-types'

import Icon from 'components/Iconly'
import { convertNumber, makePercentage } from 'lib/numbers'
import Text from 'components/Text'
import MetricCard from './MetricCard'

const styles = {
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    itemWrapper: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #EBF0F9',
        borderRadius: 10,
        padding: '15px 0px 15px 15px',
        width: '100%',
    },
    profileIconWrapper: {
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: 40,
        fontSize: 20,
        backgroundColor: '#F5EBFF',
        color: '#AE92C9',
    },
    activityIconWrapper: {
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: 40,
        fontSize: 20,
        backgroundColor: '#ECFCFF',
        color: '#68ADBA',
    },
}

const MetricCards = ({ data, count, label }) => {
    const growthCount = data.reduce((acc, curr) => (acc || 0) + Number(curr.value), 0)
    // const growthText = (growthCount > 0 ? '+' : '') + convertNumber(growthCount, 2)
    const growthText = (growthCount > 0 ? '+' : '') + makePercentage(count ? (growthCount / count) : 0)
    const countText = convertNumber(count, 2)

    return (
        <div style={styles.wrapper}>
            <MetricCard
                icon={'Profile'}
                label={label}
                value={countText}
            />
            <div style={styles.itemWrapper}>
                <div style={styles.profileIconWrapper}>
                    <Icon type={'Profile'} bold/>
                </div>
                <div style={{ width: 10 }}/>
                <div>
                    <Text
                        children={label}
                        color={'grey2'}
                        size={'p2'}
                    />
                    <Text
                        children={countText}
                        color={'black'}
                        weight={'bold'}
                        size={'h5'}
                    />
                </div>
            </div>
            <div style={{ width: 30 }}/>
            <div style={styles.itemWrapper}>
                <div style={styles.activityIconWrapper}>
                    <Icon type={'Activity'} bold/>
                </div>
                <div style={{ width: 10 }}/>
                <div>
                    <Text
                        children={'Last 30 days'}
                        color={'grey2'}
                        size={'p2'}
                    />
                    <Text
                        children={growthText}
                        color={'black'}
                        weight={'bold'}
                        size={'h5'}
                    />
                </div>
            </div>
        </div>
    )
}

MetricCards.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        data: PropTypes.string,
        value: PropTypes.number,
    })),
    count: PropTypes.number,
    label: PropTypes.string,
}

MetricCards.defaultProps = {
    data: [],
    count: 0,
    label: 'Total fans',
}

export default MetricCards
