import PropTypes from 'prop-types'

import AnalyticsTitle from './AnalyticsTitle'
import MetricGrid from './MetricGrid'
import YouTubeAgeCard from './YouTubeAgeCard'
import AudienceCard from './AudienceCard'
import GenderCard from './GenderCard'

const YouTubeAudience = ({ genders, ages, countries, sources, sourcesOS, devices }) => {
    if ((!genders || !genders.male) && (!ages || !ages.length) && (!countries || !countries.length)) return null

    return (
        <div>
            <div style={{ height: 60 }}/>
            <AnalyticsTitle title={'Audience Overview'}/>
            <MetricGrid>
                {ages && ages.length
                    ? <YouTubeAgeCard
                        title={'Age'}
                        data={ages}
                    />
                    : null
                }
                {genders && genders.male
                    ? <GenderCard
                        data={genders}
                    />
                    : null
                }
                {countries && countries.length
                    ? <AudienceCard
                        title={'Countries'}
                        data={countries}
                    />
                    : null
                }
                {sources && sources.length
                    ? <AudienceCard
                        title={'Traffic Sources'}
                        data={sources}
                    />
                    : null
                }
                {sourcesOS && sourcesOS.length
                    ? <AudienceCard
                        title={'Operating Systems'}
                        data={sourcesOS}
                    />
                    : null
                }
                {devices && devices.length
                    ? <AudienceCard
                        title={'Devices'}
                        data={devices}
                    />
                    : null
                }
            </MetricGrid>
        </div>
    )
}

YouTubeAudience.propTypes = {
    genders: PropTypes.object,
    ages: PropTypes.array,
    countries: PropTypes.array,
    sources: PropTypes.array,
    sourcesOS: PropTypes.array,
    devices: PropTypes.array,
}

YouTubeAudience.defaultProps = {
    genders: {},
    ages: [],
    countries: [],
    sources: [],
    sourcesOS: [],
    devices: [],
}

export default YouTubeAudience
