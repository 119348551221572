import PropTypes from 'prop-types'
import { animated } from '@react-spring/web'

import { convertNumber } from 'lib/numbers'

import Image from 'components/Image'
import Text from 'components/Text'

const styles = {
    wrapper: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    gridMetrics: {
        marginTop: -5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    metricWrapper: {
        background: '#fff',
        width: '100%',
        display: 'inline-block',
        textAlign: 'center',
        paddingTop: 20,
        paddingBottom: 20,
    },
}

const FullPagePost = ({ animatedProps, tabs, targetPost, height }) => {
    return (
        <div style={{
            ...styles.wrapper,
            alignItems: 'center',
        }}>
            <div style={{
                background: '#fff',
                borderRadius: 5,
                display: 'flex',
                height: height,
                zIndex: 3,
            }}>
                <Image
                    height={'100%'}
                    width={'100%'}
                    src={targetPost.pic}
                    borderRadius={0}
                    objectFit={'contain'}
                    fallbackType={'contentV1'}
                />
                <animated.div
                    style={{
                        ...styles.gridMetrics,
                        ...animatedProps,
                    }}
                >
                    <div style={styles.flex}>
                        {tabs.map((tab, idx) => {
                            return (
                                <div style={styles.metricWrapper} key={idx}>
                                    <Text
                                        size={'h1'}
                                        weight={'bold'}
                                        children={convertNumber(targetPost[tab.key])}
                                    />
                                    <Text
                                        color={'text'}
                                        children={tab.label}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </animated.div>
            </div>
        </div>
    )
}

FullPagePost.propTypes = {
    animatedProps: PropTypes.object.isRequired,
    tabs: PropTypes.array.isRequired,
    targetPost: PropTypes.object.isRequired,
    height: PropTypes.number.isRequired,
}

export default FullPagePost
