export const styles = {
    wrapper: {
        background: '#fff',
        // boxShadow: '0px 0px 10px rgba(205, 213, 231, 0.4)',
        // border: '1px solid #EBF0F9',
        paddingBottom: 15,
        paddingTop: 35,
        borderRadius: 15,
        boxShadow: '0px 0px 46.6167px rgba(205, 213, 231, 0.5)',
    },
    postsImageWrapper: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-around',
        paddingLeft: 43,
        paddingRight: 5,
        overflow: 'hidden',
    },
    imageWrapper: {
        height: 120,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
    },
    largePostsImgWrapper: {
        display: 'flex',
        paddingLeft: 35,
        overflowX: 'scroll',
        overflowY: 'hidden',
        scrollBehavior: 'smooth',
    },
    largeImg: {
        marginRight: 15,
        position: 'relative',
        cursor: 'pointer',
        transition: 'opacity, transform 0.2s',
    },
    title: {
        marginLeft: 35,
    },
    date: {
        fontSize: 12,
        fontWeight: 700,
        marginTop: 5,
        // transform: 'rotate(90deg)',
        width: 55,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        color: '#BCC6D9',
    },
    date2: {
        fontSize: 16,
        fontWeight: 600,
        marginTop: 15,
        // transform: 'rotate(90deg)',
        width: '100%',
        textAlign: 'center',
        color: '#BCC6D9',
    },
    contentItem: {
        position: 'absolute',
        zIndex: 1,
        background: 'rgba(17, 17, 24, 0.4)',
        backdropFilter: 'blur(10px)',
        borderRadius: 15,
        padding: '7.5px 15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentItemEngText: {
        color: '#FFFFFF',
        fontWeight: 600,
        fontSize: 18,
    },
    fullPageWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    scrollWrapper: {
        position: 'relative',
    },
    scrollIndicator: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    scrollButton: {
        height: 50,
        width: 50,
        borderRadius: 50,
        background: 'linear-gradient(275.12deg, #04ECFD -46.59%, #2772FF 109.3%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        fontSize: 26,
        boxShadow: '0px 0px 30px rgba(205, 213, 231, 1)',
        cursor: 'pointer',
    },
}
