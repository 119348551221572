import PropTypes from 'prop-types'
import ChannelsCarousel from './ChannelsCarousel'
import Analytics from './Analytics'
import ProfileHeader from './ProfileHeader'
import Header from './Header'

const styles = {
    wrapper: {
        background: '#f4f4f4',
        // height: '100%',
        width: '100%',
        // overflow: 'scroll',
    },
}

const MobileLayout = ({ profile, analytics, onContact, activeChannel, setActiveChannel }) => {
    const handleChannelChange = (id) => {
        const start = document.getElementById('start').getBoundingClientRect()
        window.scrollTop = window.scrollY + start.top
        setActiveChannel(id)
    }

    return (
        <div style={styles.wrapper}>
            <Header
                profilePic={profile.pic}
                name={profile.fullName || profile.username}
            />
            <div style={{ height: 60 }}/>
            <ProfileHeader {...profile} />
            <ChannelsCarousel
                channels={profile.channels}
                setActiveChannel={handleChannelChange}
                activeChannel={activeChannel}
            />
            <div id={'start'}/>
            <Analytics
                data={analytics[activeChannel.id]}
                vendor={activeChannel.vendor}
            />
        </div>
    )
}

MobileLayout.propTypes = {
    profile: PropTypes.object,
    analytics: PropTypes.object,
    onContact: PropTypes.func,
    activeChannel: PropTypes.object,
    setActiveChannel: PropTypes.func,
}

MobileLayout.defaultProps = {
    profile: {},
    analytics: {},
    onContact: () => null,
    activeChannel: {},
    setActiveChannel: () => null,
}

export default MobileLayout
