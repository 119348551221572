
import PropTypes from 'prop-types'

import Text from 'components/Text'
import { convertNumber } from 'lib/numbers'

import ChartBar2Value from './ChartBar2Value'
import MetricCard from './MetricCardHorizontal'

const styles = {
    wrapper: {
        background: '#fff',
        paddingTop: 15,
        paddingBottom: 15,
        borderRadius: 15,
        // borderTopRightRadius: 15,
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
    },
    metricCards: {
        display: 'flex',
    },
    barChartWrapper: {
        // marginLeft: -15,
    },
    line: {
        background: '#EBF0F9',
        height: 1,
        width: '100%',
    },
    title: {
        marginLeft: 15,
        marginBottom: 15,
    },
}

const YoutubeSubs = ({ data, growth, count, totalGained, totalLost }) => {
    if (!data.length) return null

    return (
        <div style={{
            ...styles.wrapper,
        }}>
            <div style={styles.title}>
                <Text
                    children={'Subscribers Growth'}
                    size={'h6'}
                    weight={'bold'}
                />
            </div>
            <div style={styles.line}/>
            <div style={{ height: 15 }}/>
            <div style={styles.metricCards}>
                <MetricCard
                    value={convertNumber(count)}
                    label={'Total Subs'}
                    icon={'Profile'}
                    iconBg={'#F5F9FF'}
                    iconColor={'#2772FF'}

                />
                <div style={{ width: 30 }}/>
                <MetricCard
                    value={growth > 0 ? `+${convertNumber(growth)}` : convertNumber(growth)}
                    label={'Last 30 days'}
                    icon={'Activity'}
                    iconBg={'#F5F9FF'}
                    iconColor={'#2772FF'}
                />
            </div>
            <div style={{ height: 15 }}/>
            <div style={styles.line}/>
            {totalGained !== null && totalGained !== null
                ? (
                    <>
                        <div style={{ height: 15 }}/>
                        <div style={styles.metricCards}>
                            <MetricCard
                                value={convertNumber(totalGained)}
                                label={'Subs Gained'}
                                icon={'Arrow-Up'}
                                iconBg={'#E5F5EF'}
                                iconColor={'#00985F'}

                            />
                            <div style={{ width: 30 }}/>
                            <MetricCard
                                value={(totalLost === 0 ? '' : '-') + convertNumber(totalLost)}
                                label={'Subs Lost'}
                                icon={'Arrow-Down'}
                                iconBg={'#FFF3F5'}
                                iconColor={'#FF274B'}
                            />
                        </div>
                    </>
                )
                : null}
            <div style={{ height: 15 }}/>
            <div style={styles.line}/>
            <div style={{ height: 15 }} />
            <div style={styles.barChartWrapper}>
                <ChartBar2Value data={data} />
            </div>
        </div>
    )
}

YoutubeSubs.propTypes = {
    growth: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.shape({
        data: PropTypes.string,
        value: PropTypes.number,
    })),
    count: PropTypes.number,
    totalGained: PropTypes.number,
    totalLost: PropTypes.number,
}

YoutubeSubs.defaultProps = {
    growth: 0,
    data: [],
    count: 0,
    totalGained: 0,
    totalLost: 0,
}

export default YoutubeSubs
