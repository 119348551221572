import PropTypes from 'prop-types'
// import ReactPlayer from 'react-player/lazy'

import Image from 'components/Image'

const MediaItem = ({ data }) => {
    return (
        <Image
            src={data.pic}
            width={'100%'}
            height={'100%'}
            borderRadius={0}
            objectFit={'cover'}
            fallbackType={'storyFallback'}
            loaderColor={'black'}
        />
    )

    // if (data.type === 'image') {
    //     return (
    //         <Image
    //             src={data.pic}
    //             width={'100%'}
    //             height={'calc(100vh - 110px - env(safe-area-inset-bottom))'}
    //             borderRadius={0}
    //             objectFit={'cover'}
    //             fallbackType={'contentV1'}
    //             loaderColor={'black'}
    //         />
    //     )
    // }

    // return (
    //     <ReactPlayer
    //         url={data.url}
    //         controls={false}
    //         playsinline
    //         loop
    //         height={'100%'}
    //         width={'100%'}
    //         playing={isVisible}
    //         volume={1}
    //         muted={false}
    //         fallback={(
    //             <Image
    //                 src={data.pic}
    //                 borderRadius={0}
    //                 objectFit={'cover'}
    //                 fallbackType={'contentV1'}
    //                 loaderColor={'black'}
    //             />
    //         )}
    //         config={{
    //             youtube: { playerVars: { modestbranding: 1 } },
    //             file: { attributes: { poster: data.pic } },
    //         }}
    //     />
    // )
}

MediaItem.propTypes = {
    data: PropTypes.object.isRequired,
    isVisible: PropTypes.bool.isRequired,
}

export default MediaItem
