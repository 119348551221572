import PropTypes from 'prop-types'
import { useState } from 'react'

import { makePercentage, convertNumber } from 'lib/numbers'
import { capitalizeString } from 'lib/strings'
import { colors } from 'styles'
import Text from 'components/Text'
import Tabs from './Tabs'

const styles = {
    wrapper: {
        background: '#fff',
        // boxShadow: '0px 0px 10px rgba(205, 213, 231, 0.4)',
        // border: '1px solid #EBF0F9',
        paddingBottom: 15,
        paddingTop: 15,
        borderRadius: 15,
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
    },
    barsWrapper: {
        padding: '0px 15px',
        marginTop: -7.5,
    },
    title: {
        marginLeft: 15,
    },
    barWrapper: {
        paddingTop: 15,
    },
    barInfoWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 9,
    },
    barName: {
        fontSize: 14,
        fontWeight: 600,
        color: colors.black,
    },
    barPercentage: {
        fontSize: 14,
        fontWeight: 500,
    },
    barDataWrapper: {
        height: 10,
        position: 'relative',
    },
    barDataActive: {
        backgroundColor: '#2772FF',
        height: 12.5,
        borderRadius: 5,
        width: '100%',
        position: 'absolute',

    },
    barDataBackground: {
        backgroundColor: colors.lightBlue,
        width: '100%',
        borderRadius: 5,
        height: 12.5,
    },
}

// eslint-disable-next-line
const Bar = ({ name, total, perc, count}) => {
    return (
        <div style={styles.barWrapper}>
            <div style={styles.barInfoWrapper}>
                <div style={styles.barName}>{name}</div>
                <div style={styles.barPercentage}>{convertNumber(total / count)}</div>
            </div>
            <div style={styles.barDataWrapper}>
                <div style={{ ...styles.barDataActive, width: makePercentage(perc) }} />
                <div style={styles.barDataBackground} />
            </div>
        </div>
    )
}

const InstagramPostType = ({ typesMap, tabs }) => {
    const [ activeTab, setActiveTab ] = useState(tabs[0] && tabs[0].key)

    return (
        <div style={styles.wrapper}>
            <div style={styles.title}>
                <Text
                    children={'Top Post Types'}
                    size={'h6'}
                    weight={'bold'}
                />
            </div>
            <div style={{ height: 15 }}/>
            <Tabs
                tabs={tabs}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
            />
            <div style={{ height: 15 }}/>
            <div style={styles.barsWrapper}>
                {Object.keys(typesMap).filter(i => i !== 'total').map(i => (
                    <Bar
                        key={i}
                        name={capitalizeString(i)}
                        total={typesMap[i][activeTab]}
                        count={typesMap[i].count}
                        perc={
                            (typesMap.total && typesMap.total[activeTab])
                                ? ((typesMap[i][activeTab] / typesMap[i].count) / typesMap.total[activeTab])
                                : 0}
                    />
                ))}
            </div>
            <div style={{ height: 15 }}/>
        </div>
    )
}

InstagramPostType.propTypes = {
    typesMap: PropTypes.object,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
    })),
}

InstagramPostType.defaultProps = {
    typesMap: null,
    tabs: [],
}

export default InstagramPostType
