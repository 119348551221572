import { colors } from 'styles'
import { makePercentage, convertNumber } from 'lib/numbers'

const styles = {
    title: {
        marginLeft: 35,
    },
    barChartWrapper: {
        marginLeft: -15,
    },
    barWrapper: {
        paddingTop: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    barName: {
        fontSize: 16,
        fontWeight: 400,
        color: colors.text,
        width: 130,
    },
    barPercentage: {
        fontSize: 18,
        fontWeight: 600,
        width: 90,
        textAlign: 'center',
    },
    barDataWrapper: {
        height: 35,
        position: 'relative',
        width: '100%',
    },
    barDataActive: {
        backgroundColor: '#2772FF',
        height: 35,
        borderRadius: 5,
        width: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    barDataBackground: {
        backgroundColor: colors.lightBlue,
        width: '100%',
        borderRadius: 5,
        height: 35,
    },
    percNumb: {
        color: '#fff',
        fontSize: 14,
        fontWeight: 600,
    },
}

// eslint-disable-next-line
const Bar2 = ({ name, total, perc, count }) => {
    return (
        <div style={styles.barWrapper}>
            <div style={styles.barName}>{name}</div>
            <div style={styles.barDataWrapper}>
                <div style={{ ...styles.barDataActive, width: makePercentage(perc) }}/>
                <div style={styles.barDataBackground} />
            </div>
            <div style={styles.barPercentage}>{convertNumber(total / count)}</div>
        </div>
    )
}

export default Bar2
