
import PropTypes from 'prop-types'
import { useRef, useEffect, useState } from 'react'

import { InstagramIcon, TiktokIcon, YoutubeIcon } from 'components/Icons'
import Text from 'components/Text'
import logo from 'resources/images/logo-black.png'
import Iconly from 'components/Iconly'
import { capitalizeString } from 'lib/strings'

const styles = {
    wrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 5,
        boxShadow: '0px -4px 35px rgba(205, 213, 231, 0.4)',
        backgroundColor: '#fff',
        height: 70,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    innerWrapper: {
        maxWidth: 1260,
        minWidth: 1024,
        width: 1260,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    channelsWrapper: {
        maxWidth: 800,
        minWidth: 800,
        position: 'relative',
    },
    channelItem: {
        padding: '0px 15px',
        display: 'flex',
        alignItems: 'center',
        height: 70,
        cursor: 'pointer',
    },
    logoWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: 350,
        paddingLeft: 60,
    },
    scrollRightArrowWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        justifyContent: 'flex-end',
        width: 70,
        display: 'flex',
        alignItems: 'center',
        background: 'linear-gradient(269.2deg, #FFFFFF 37.68%, rgba(255, 255, 255, 0) 109.71%)',
        cursor: 'pointer',
    },
    scrollLeftArrowWrapper: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        justifyContent: 'flex-start',
        width: 70,
        display: 'flex',
        alignItems: 'center',
        background: 'linear-gradient(90deg, #FFFFFF 37%, rgba(255, 255, 255, 0) 109%)',
        cursor: 'pointer',
    },
    scrollableWrapper: {
        overflow: 'scroll',
        display: 'flex',
        scrollBehavior: 'smooth',
        paddingRight: 70,
    },
}

const getVendorIcon = (vendor) => {
    switch (vendor) {
        case 'instagram': return <InstagramIcon />
        case 'youtube': return <YoutubeIcon />
        case 'tiktok': return <TiktokIcon />
        default: return null
    }
}

const Header = ({ channels, activeChannel, setActiveChannel }) => {
    const [ rightScrollIndicator, setRightScrollIndicator ] = useState(false)
    const [ leftScrollIndicator, setLeftScrollIndicator ] = useState(false)

    const scrollRef = useRef(null)

    useEffect(() => {
        const shouldHaveScroll = scrollRef.current.scrollWidth > 800
        const func = (e) => {
            if (scrollRef.current.scrollLeft > 100 && !leftScrollIndicator) {
                setLeftScrollIndicator(true)
            }
            if (scrollRef.current.scrollLeft < 100 && leftScrollIndicator) {
                setLeftScrollIndicator(false)
            }
        }

        if (shouldHaveScroll) {
            setRightScrollIndicator(true)
            scrollRef.current.addEventListener('scroll', func)
        }

        return () => {
            scrollRef && scrollRef.current && scrollRef.current.removeEventListener('scroll', func)
        }
    }, [leftScrollIndicator])

    const onSelectChannel = (i, id) => {
        const itemPosition = document.getElementById(id).getBoundingClientRect()
        setActiveChannel(i)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        const widthOutsideOfHeader = (window.innerWidth - 750)

        scrollRef.current.scrollLeft += ((itemPosition.left - widthOutsideOfHeader))
    }

    const scrollRight = () => { scrollRef.current.scrollLeft += 500 }
    const scrollLeft = () => { scrollRef.current.scrollLeft -= 500 }

    return (
        <>
            <div style={styles.wrapper}>
                <div style={styles.innerWrapper}>
                    <div style={styles.logoWrapper}>
                        <img
                            src={logo}
                            alt={'mysocial'}
                            style={{
                                width: 130,
                            }}
                        />
                    </div>
                    <div style={{ width: 90 }}/>
                    <div style={styles.channelsWrapper} >
                        <div
                            ref={scrollRef}
                            style={styles.scrollableWrapper}>
                            {channels.map((i, idx) => (
                                <div
                                    key={i.id}
                                    id={`${i.id}-desktop`}
                                    style={{
                                        ...styles.channelItem,
                                        borderBottom: activeChannel.id === i.id ? '3px solid #2772FF' : 'none',
                                    }}
                                    onClick={() => onSelectChannel(i, `${i.id}-desktop`)}
                                >
                                    {getVendorIcon(i.vendor)}
                                    <div style={{ width: 10 }}/>
                                    <Text
                                    // style={{ whiteSpace: 'nowrap' }}
                                        children={capitalizeString(i.username)}
                                        color={activeChannel.id === i.id ? 'primary' : 'grey2'}
                                        weight={activeChannel.id === i.id ? 'bold' : 'medium'}
                                        size={'h5'}
                                        wrapperStyle={{ whiteSpace: 'nowrap' }}
                                    />
                                </div>
                            ))}
                        </div>
                        {rightScrollIndicator && (
                            <div
                                onClick={() => scrollRight()}
                                style={styles.scrollRightArrowWrapper}>
                                <Iconly
                                    type={'Arrow-Right-2'}
                                    style={{
                                        color: '#2772FF',
                                        fontSize: 25,
                                    }}
                                />
                            </div>
                        )}
                        <div
                            onClick={() => scrollLeft()}
                            style={{
                                ...styles.scrollLeftArrowWrapper,
                                opacity: leftScrollIndicator ? 1 : 0,
                                transition: 'opacity 0.2s',
                            }}>
                            <Iconly
                                type={'Arrow-Left-2'}
                                style={{
                                    color: '#2772FF',
                                    fontSize: 25,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: 50 }}/>
        </>
    )
}

Header.propTypes = {
    channels: PropTypes.array,
    activeChannel: PropTypes.object,
    setActiveChannel: PropTypes.func,
}

Header.defaultProps = {
    channels: [],
    activeChannel: {},
    setActiveChannel: () => null,
}

export default Header
