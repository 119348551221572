
import PropTypes from 'prop-types'

import InstagramOverview from '../components/InstagramOverview'
import LastPosts from '../components/LastPosts'

import FollowersGrowth from '../components/FollowersGrowth'
import InstagramPostType from '../components/InstagramPostType'
import InstagramAudience from '../components/InstagramAudience'
// import InstagramInteractions from '../Components/InstagramInteractions'
import ProfileOverview from '../components/ProfileOverview'
import LastStories from '../components/LastStories'
import Loader from '../components/Loader'
import NoAnalytics from '../components/NoAnalytics'

const Instagram = ({ data }) => {
    if (data === undefined) return <Loader />
    if (data === null) return <NoAnalytics vendor={'Instagram'} />

    const postsProps = {
        posts: data.postsList.slice(0, 10).reverse(),
        tabs: [
            data.needInsights ? null : { key: 'impressions', label: 'Impressions' },
            data.needInsights ? null : { key: 'reach', label: 'Reach' },
            { key: 'likes', label: 'Likes' },
            { key: 'comments', label: 'Comments' },
        ].filter(i => i !== null),
    }

    const followersProps = {
        growth: data.followersGrowth,
        count: data.followers,
        data: data.followersGrowthList,
        vendor: 'instagram',
    }

    const audienceProps = {
        cities: data.cities,
        countries: data.countries,
        genders: data.genders,
        ages: data.ages,
    }
    const storiesProps = {
        tabs: [
            { key: 'reach', label: 'Reach' },
            { key: 'impressions', label: 'Impressions' },
        ],
        posts: data.storiesDays.slice(0, 10),
    }

    const postsTypeProps = {
        typesMap: !data.postsList.length
            ? null
            : data.postsList
                .reduce((acc, curr) => ({
                    ...acc,
                    total: {
                        count: ((acc.total && acc.total.count) || 0) + 1,
                        impressions: ((acc.total && acc.total.impressions) || 0) > curr.impressions ? acc.total.impressions : curr.impressions,
                        reach: ((acc.total && acc.total.reach) || 0) > curr.reach ? acc.total.reach : curr.reach,
                        likes: ((acc.total && acc.total.likes) || 0) > curr.likes ? acc.total.likes : curr.likes,
                        comments: ((acc.total && acc.total.comments) || 0) > curr.comments ? acc.total.comments : curr.comments,
                    },
                    [curr.type]: {
                        count: ((acc[curr.type] && acc[curr.type].count) || 0) + 1,
                        impressions: ((acc[curr.type] && acc[curr.type].impressions) || 0) + curr.impressions,
                        reach: ((acc[curr.type] && acc[curr.type].reach) || 0) + curr.reach,
                        likes: ((acc[curr.type] && acc[curr.type].likes) || 0) + curr.likes,
                        comments: ((acc[curr.type] && acc[curr.type].comments) || 0) + curr.comments,
                    },
                }), {}),
        tabs: [
            data.needInsights ? null : { key: 'impressions', label: 'Impressions' },
            data.needInsights ? null : { key: 'reach', label: 'Reach' },
            { key: 'likes', label: 'Likes' },
            { key: 'comments', label: 'Comments' },
        ].filter(i => i !== null),
    }

    const interactionsProps = {
        tabs: [
            { key: 'impressions', label: 'Impressions' },
            { key: 'reach', label: 'Reach' },
            { key: 'profileViews', label: 'Profile Views' },
            { key: 'linkClicks', label: 'Link Clicks' },
        ],
        tabsData: {
            profileViews: {
                count: data.needInsights ? 0 : data.current30.reduce((acc, curr) => (acc || 0) + curr.profileViews, 0),
                lastCount: data.needInsights ? 0 : data.last30.reduce((acc, curr) => (acc || 0) + curr.profileViews, 0),
            },
            linkClicks: {
                count: data.needInsights ? 0 : data.current30.reduce((acc, curr) => (acc || 0) + curr.linkClicks, 0),
                lastCount: data.needInsights ? 0 : data.last30.reduce((acc, curr) => (acc || 0) + curr.linkClicks, 0),
            },
            impressions: {
                count: data.needInsights ? 0 : data.current30.reduce((acc, curr) => (acc || 0) + curr.impressions, 0),
                lastCount: data.needInsights ? 0 : data.last30.reduce((acc, curr) => (acc || 0) + curr.impressions, 0),
            },
            reach: {
                count: data.needInsights ? 0 : data.current30.reduce((acc, curr) => (acc || 0) + curr.reach, 0),
                lastCount: data.needInsights ? 0 : data.last30.reduce((acc, curr) => (acc || 0) + curr.reach, 0),
            },
        },
        data: data.needInsights ? [] : data.current30,
    }

    return (
        <div style={{ padding: 15, overflow: 'visible' }}>
            <div style={{ height: 15 }}/>
            <InstagramOverview {...data}/>
            {!data.postsList.length
                ? null
                : (
                    <>
                        <div style={{ height: 30 }}/>
                        <LastPosts
                            {...postsProps}
                            vendor={'instagram'}
                            openUrl={`https://instagram.com/${data.name}`}
                        />
                    </>
                )}
            {!data.postsList.length
                ? null
                : (
                    <>
                        <div style={{ height: 30 }}/>
                        <InstagramPostType {...postsTypeProps}/>
                    </>
                )}

            {data.needInsights ? null : <div style={{ height: 30 }}/>}
            {data.needInsights ? null : <InstagramAudience {...audienceProps}/>}
            {data.needInsights ? null : <LastStories {...storiesProps}/>}

            {!followersProps.data.length
                ? null
                : (
                    <>
                        <div style={{ height: 30 }}/>
                        <FollowersGrowth {...followersProps}/>
                    </>
                )}

            {data.needInsights ? null : <div style={{ height: 30 }}/>}
            {data.needInsights ? null : <ProfileOverview {...interactionsProps}/>}
        </div>
    )
}

Instagram.propTypes = {
    data: PropTypes.object,
}

Instagram.defaultProps = {
    data: undefined,
}

export default Instagram
