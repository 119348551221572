
import PropTypes from 'prop-types'

import Text from 'components/Text'
import { colors } from 'styles'
import { makePercentage } from 'lib/numbers'

import { PieChart } from 'recharts/lib/chart/PieChart'
import { Cell } from 'recharts/lib/component/Cell'
import { Pie } from 'recharts/lib/polar/Pie'

const styles = {
    wrapper: {
        background: '#fff',
        paddingTop: 15,
        paddingBottom: 15,
        borderRadius: 15,
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
    },
    title: {
        marginLeft: 15,
        marginBottom: 15,
    },
    barWrapper: {
        paddingTop: 15,
    },
    barInfoWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 9,
    },
    barName: {
        fontSize: 14,
        fontWeight: 600,
        color: colors.black,
    },
    barPercentage: {
        fontSize: 14,
        fontWeight: 500,
    },
    barDataWrapper: {
        height: 10,
        position: 'relative',
    },
    barDataActive: {
        backgroundColor: '#2772FF',
        height: 12.5,
        borderRadius: 5,
        width: '100%',
        position: 'absolute',

    },
    barDataBackground: {
        backgroundColor: colors.lightBlue,
        width: '100%',
        borderRadius: 5,
        height: 12.5,
    },
    line: {
        background: '#EBF0F9',
        height: 1,
        width: '100%',
    },
    padding: {
        paddingLeft: 15,
        paddingRight: 15,
    },
    labelWrapper: {
        marginTop: 30,
        marginBottom: 30,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        bottom: 10,
        right: 30,
        left: 30,
    },
    label: {
        color: '#00A6E8',
        alignItems: 'center',
        display: 'flex',
    },
    maleDot: {
        height: 7.5,
        width: 7.5,
        background: '#2772FF',
        borderRadius: 10,
        marginRight: 5,
    },
    femaleDot: {
        height: 7.5,
        width: 7.5,
        background: '#F18DED',
        borderRadius: 10,
        marginRight: 5,
    },
    otherDot: {
        height: 7.5,
        width: 7.5,
        background: '#A8B0C0',
        borderRadius: 10,
        marginRight: 5,
    },
}

// eslint-disable-next-line
const Bar = ({ name, value }) => {
    if (!value) return null

    const perc = value < 0.1 ? makePercentage(value, 2) : makePercentage(value)

    return (
        <div style={styles.barWrapper}>
            <div style={styles.barInfoWrapper}>
                <div style={styles.barName}>{name}</div>
                <div style={styles.barPercentage}>{perc}</div>
            </div>
            <div style={styles.barDataWrapper}>
                <div style={{ ...styles.barDataActive, width: perc }} />
                <div style={styles.barDataBackground} />
            </div>
        </div>
    )
}

const InstagramAudience = ({ cities, countries, ages, genders }) => {
    const sortDesc = (arr) => arr.sort((a, b) => (a.value > b.value ? -1 : 1))

    return (
        <div style={styles.wrapper}>
            <div style={styles.title}>
                <Text
                    children={'Audience'}
                    size={'h6'}
                    weight={'bold'}
                />
            </div>
            <div style={styles.line}/>
            {genders && genders.male
                ? (
                    <div style={styles.padding}>
                        <div style={{ height: 30 }} />
                        <Text size={'h6'} weight={'bold'} children={'Gender'}/>
                        <div style={styles.piechartWrapper}>
                            <PieChart width={window.innerWidth - 60} height={200}>
                                <Pie
                                    data={[
                                        { name: 'female', value: genders.female },
                                        { name: 'male', value: genders.male },
                                        { name: 'other', value: genders.other },
                                    ]}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    <Cell key={'cell-female'} fill={'#F18DED'} />
                                    <Cell key={'cell-male'} fill={'#2772FF'} />
                                    <Cell key={'cell-other'} fill={'#A8B0C0'} />
                                </Pie>
                            </PieChart>
                        </div>
                        <div style={styles.labelWrapper}>
                            <div style={styles.label}>
                                <div style={styles.maleDot} />
                                <Text
                                    size={'p'}
                                    weight={'medium'}
                                    color={'black'}
                                    children={`${genders.male.toFixed(0)}% Male`}
                                />
                            </div>
                            <div style={styles.label}>
                                <div style={styles.femaleDot} />
                                <Text
                                    size={'p'}
                                    weight={'medium'}
                                    color={'black'}
                                    children={`${genders.female.toFixed(0)}% Female`}
                                />
                            </div>
                            {genders.other && (
                                <div style={styles.label}>
                                    <div style={styles.otherDot} />
                                    <Text
                                        size={'p'}
                                        weight={'medium'}
                                        color={'black'}
                                        children={`${genders.other.toFixed(0)}% Other`}
                                    />
                                </div>
                            )
                            }
                        </div>
                        <div style={styles.line}/>
                    </ div>
                )
                : null}
            {ages && ages.length
                ? (
                    <div style={styles.padding}>
                        <div style={{ height: 30 }} />
                        <Text size={'h6'} weight={'bold'} children={'Age'}/>
                        <div style={{ height: 15 }} />
                        {sortDesc(ages).slice(0, 5).map(i => (
                            <Bar key={i.type} name={i.type} value={i.value / 100}/>
                        ))}
                        <div style={{ height: 30 }}/>
                        <div style={styles.line}/>
                    </ div>
                )
                : null}
            {countries && countries.length
                ? (
                    <div style={styles.padding}>
                        <div style={{ height: 30 }} />
                        <Text size={'h6'} weight={'bold'} children={'Countries'}/>
                        <div style={{ height: 15 }} />
                        {countries.map(i => (
                            <Bar key={i.type} name={i.type} value={i.value / 100}/>
                        ))}
                        <div style={{ height: 30 }}/>
                        <div style={styles.line}/>
                    </ div>
                )
                : null}
            {cities && cities.length
                ? (
                    <div style={styles.padding}>
                        <div style={{ height: 30 }} />
                        <Text size={'h6'} weight={'bold'} children={'Cities'}/>
                        <div style={{ height: 15 }} />
                        {cities.map(i => (
                            <Bar key={i.type} name={i.type} value={i.value / 100}/>
                        ))}
                    </div>
                )
                : null}
            <div style={{ height: 15 }}/>
        </div>
    )
}

InstagramAudience.propTypes = {
    cities: PropTypes.array,
    countries: PropTypes.array,
    ages: PropTypes.array,
    genders: PropTypes.object,
}

InstagramAudience.defaultProps = {
    cities: [],
    countries: [],
    ages: [],
    genders: null,
}

export default InstagramAudience
