import PropTypes from 'prop-types'
import { useState } from 'react'

import ChartBar from './ChartBar'
import Tabs from './Tabs3'
import AnalyticsTitle from './AnalyticsTitle'

const styles = {
    wrapper: {
        background: '#fff',
        // padding: '30px',
        boxShadow: '0px 0px 46.6167px rgba(205, 213, 231, 0.5)',
        borderRadius: 15,
        overflow: 'hidden',
    },
    metricCards: {
        display: 'flex',
    },
    barChartWrapper: {
        // marginLeft: -15,
    },
    line: {
        background: '#EBF0F9',
        height: 1,
        width: '100%',
    },
    title: {
        marginLeft: 15,
    },
}

const ProfileOverview = ({ data, tabs, tabsData }) => {
    const [ activeTab, setActiveTab ] = useState(tabs[0] && tabs[0].key)

    if (!data.length) return null

    return (
        <>
            <div style={{ height: 60 }}/>
            <AnalyticsTitle title={'Profile Overview'}/>
            <div style={styles.wrapper}>
                <div style={{ overflow: 'scroll' }}>
                    <Tabs
                        tabs={tabs}
                        tabsData={tabsData}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                    />
                </div>
                <div style={{ height: 45 }}/>
                <div style={styles.barChartWrapper}>
                    <ChartBar data={[...data].reverse().map(i => ({ date: i.date, value: i[activeTab] }))} />
                </div>
                <div style={{ height: 30 }}/>
            </div>
        </>
    )
}

ProfileOverview.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        data: PropTypes.string,
        value: PropTypes.number,
    })),
    tabs: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
    })),
    tabsData: PropTypes.object,
}

ProfileOverview.defaultProps = {
    tabsData: {},
    growth: 0,
    data: [],
    count: 0,
    tabs: [],
}

export default ProfileOverview
