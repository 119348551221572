import PropTypes from 'prop-types'
import { animated, useSpring } from '@react-spring/web'
import { useEffect } from 'react'
import { useDrag } from '@use-gesture/react'

import Icon from 'components/Iconly'

const styles = {
    closeBtn: {
        position: 'absolute',
        top: 20,
        left: 20,
        height: 50,
        width: 50,
        background: 'rgba(17, 17, 24, 0.4)',
        backdropFilter: 'blur(10px)',
        borderRadius: 30,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
    },
    background: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        background: 'rgba(17, 17, 24, 0.9)',
        zIndex: 1,
    },
}

const BoundingClientModal = ({
    children,
    targetBounds,
    modalOpen,
    close,
    itemBorderRadius,
    wrapperStyles,
    maxWidth,
    maxHeight,
    draggable,
    itemPadding,
}) => {
    const [ { top, left, right, bottom, width, height, borderRadius, y, opacity, padding }, api ] = useSpring(() => ({
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        width: 0,
        height: 0,
        borderRadius: 0,
        y: 0,
        opacity: 0,
    }))

    const closeModal = () => {
        api.start({
            width: targetBounds.width,
            top: targetBounds.top,
            bottom: targetBounds.bottom,
            right: targetBounds.right,
            left: targetBounds.left,
            height: targetBounds.height,
            borderRadius: itemBorderRadius,
            padding: 0,
            y: 0,
            opacity: 0,
        })
        close()
    }

    document.body.style.overflow = modalOpen ? 'hidden' : 'scroll'

    useEffect(() => {
        if (!modalOpen) return
        api.start({
            from: {
                width: targetBounds.width,
                top: targetBounds.top,
                bottom: targetBounds.bottom,
                right: targetBounds.right,
                left: targetBounds.left,
                height: targetBounds.height,
                borderRadius: itemBorderRadius,
                opacity: 0,
                padding: 0,
            },
            to: {
                width: maxWidth,
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
                height: maxHeight,
                borderRadius: 0,
                opacity: 1,
                padding: itemPadding,
            },
        })
    }, [modalOpen])

    // Set the drag hook and define component movement based on gesture data
    const bind = useDrag(({ active, tap, movement: [ mx, my ], elapsedTime }) => {
        if (tap) return

        const yTrigger = my / elapsedTime
        const trigger = Math.abs(yTrigger) > 0.3

        const runDropped = () => {
            if (trigger) {
                closeModal()
                return 0
            }
            if (!trigger) return 0
        }

        api.start({
            y: active ? my : runDropped(),
        })
    }, { filterTaps: true })

    if (!modalOpen) return null

    if (draggable) {
        return (
            <animated.div
                style={{
                    top,
                    left,
                    right,
                    bottom,
                    width,
                    height,
                    y,
                    zIndex: 20,
                    borderRadius,
                    padding,
                    position: 'fixed',
                    willChange: 'top,left,right,bottom,width,height,x,y',
                    overflow: 'hidden',
                    touchAction: 'none',
                    ...wrapperStyles,
                }}
                {...bind()}
            // onClick={closeModal}
            >
                {children}
                <div style={styles.background} onClick={closeModal}/>
            </animated.div>
        )
    }
    return (
        <animated.div
            style={{
                top,
                left,
                right,
                bottom,
                width,
                height,
                y,
                zIndex: 20,
                borderRadius,
                padding,
                position: 'fixed',
                willChange: 'top,left,right,bottom,width,height',
                overflow: 'hidden',
                touchAction: 'none',
            }}
            // onClick={closeModal}
        >
            <animated.div
                style={{
                    ...styles.closeBtn,
                    opacity,
                    willChange: 'opacity',
                    zIndex: 4,
                }}
            >
                <Icon
                    type={'Arrow-Down-2'}
                    // bold
                    style={{
                        color: '#FFFFFF',
                        fontSize: 25,
                    }}
                    onClick={closeModal}
                />
            </animated.div>
            <div style={{ zIndex: 2, ...wrapperStyles, height: '100%', width: '100%' }}>
                {children}
            </div>
            <div style={styles.background} onClick={closeModal}/>
        </animated.div>
    )
}

BoundingClientModal.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    targetBounds: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    modalOpen: PropTypes.bool.isRequired,
    itemBorderRadius: PropTypes.number,
    wrapperStyles: PropTypes.object,
    maxWidth: PropTypes.number.isRequired,
    maxHeight: PropTypes.number.isRequired,
    draggable: PropTypes.bool,
    itemPadding: PropTypes.number,
}

BoundingClientModal.defaultProps = {
    itemBorderRadius: 0,
    itemPadding: 0,
    wrapperStyles: {},
    draggable: false,
}

export default BoundingClientModal
