
import PropTypes from 'prop-types'

import MetricCard from '../components/MetricCard'
import MetricGrid from '../components/MetricGrid'
import LastPosts from '../components/LastPosts'
import LastStories from '../components/LastStories'
import InstagramPostType from '../components/InstagramPostType'
import ProfileOverview from '../components/ProfileOverview'
import InstagramAudience from '../components/InstagramAudience'

import Loader from '../components/Loader'
import NoAnalytics from '../components/NoAnalytics'

import { convertNumber } from 'lib/numbers'
import FollowersGrowth from '../components/FollowersGrowth'

const styles = {
    dataWrapper: {
        // padding: 10,
    },
}

const Instagram = ({ data }) => {
    if (data === undefined) {
        return (
            <div style={styles.dataWrapper}><Loader /></div>
        )
    }
    if (data === null) {
        return (
            <div style={styles.dataWrapper}><NoAnalytics vendor={'Instagram'} /></div>
        )
    }

    const postsProps = {
        posts: [...data.postsList].reverse(),
        tabs: [
            data.needInsights ? null : { key: 'impressions', label: 'Impressions' },
            data.needInsights ? null : { key: 'reach', label: 'Reach' },
            { key: 'likes', label: 'Likes' },
            { key: 'comments', label: 'Comments' },
        ].filter(i => i !== null),
    }

    const interactionsProps = {
        tabs: [
            { key: 'impressions', label: 'Impressions' },
            { key: 'reach', label: 'Reach' },
            { key: 'profileViews', label: 'Profile Views' },
            { key: 'linkClicks', label: 'Link Clicks' },
        ],
        tabsData: {
            impressions: {
                count: data.needInsights ? 0 : data.current30.reduce((acc, curr) => (acc || 0) + curr.impressions, 0),
                lastCount: data.needInsights ? 0 : data.last30.reduce((acc, curr) => (acc || 0) + curr.impressions, 0),
            },
            reach: {
                count: data.needInsights ? 0 : data.current30.reduce((acc, curr) => (acc || 0) + curr.reach, 0),
                lastCount: data.needInsights ? 0 : data.last30.reduce((acc, curr) => (acc || 0) + curr.reach, 0),
            },
            profileViews: {
                count: data.needInsights ? 0 : data.current30.reduce((acc, curr) => (acc || 0) + curr.profileViews, 0),
                lastCount: data.needInsights ? 0 : data.last30.reduce((acc, curr) => (acc || 0) + curr.profileViews, 0),
            },
            linkClicks: {
                count: data.needInsights ? 0 : data.current30.reduce((acc, curr) => (acc || 0) + curr.linkClicks, 0),
                lastCount: data.needInsights ? 0 : data.last30.reduce((acc, curr) => (acc || 0) + curr.linkClicks, 0),
            },
        },
        data: data.needInsights ? [] : data.current30,
    }

    const storiesTabs = [
        { key: 'reach', label: 'Reach' },
        { key: 'impressions', label: 'Impressions' },
    ]

    const postsTypeProps = {
        typesMap: !data.postsList.length
            ? null
            : data.postsList
                .reduce((acc, curr) => ({
                    ...acc,
                    total: {
                        count: ((acc.total && acc.total.count) || 0) + 1,
                        impressions: ((acc.total && acc.total.impressions) || 0) > curr.impressions ? acc.total.impressions : curr.impressions,
                        reach: ((acc.total && acc.total.reach) || 0) > curr.reach ? acc.total.reach : curr.reach,
                        likes: ((acc.total && acc.total.likes) || 0) > curr.likes ? acc.total.likes : curr.likes,
                        comments: ((acc.total && acc.total.comments) || 0) > curr.comments ? acc.total.comments : curr.comments,
                    },
                    [curr.type]: {
                        count: ((acc[curr.type] && acc[curr.type].count) || 0) + 1,
                        impressions: ((acc[curr.type] && acc[curr.type].impressions) || 0) + curr.impressions,
                        reach: ((acc[curr.type] && acc[curr.type].reach) || 0) + curr.reach,
                        likes: ((acc[curr.type] && acc[curr.type].likes) || 0) + curr.likes,
                        comments: ((acc[curr.type] && acc[curr.type].comments) || 0) + curr.comments,
                    },
                }), {}),
        tabs: [
            data.needInsights ? null : { key: 'impressions', label: 'Impressions' },
            data.needInsights ? null : { key: 'reach', label: 'Reach' },
            { key: 'likes', label: 'Likes' },
            { key: 'comments', label: 'Comments' },
        ].filter(i => i !== null),
    }

    const audienceData = {
        genders: data.genders,
        countries: data.countries,
        cities: data.cities,
        ages: data.ages,
    }

    const followersProps = {
        growth: data.followersGrowth,
        count: data.followers,
        list: data.followersGrowthList,
        vendor: 'instagram',
    }

    return (
        <div>
            <MetricGrid columns={3}>
                <MetricCard
                    icon={'Profile'}
                    title={'Followers'}
                    value={convertNumber(data.followers)}
                />
                <MetricCard
                    icon={'Show'}
                    title={'Avg Impressions'}
                    value={convertNumber(data.impressions)}
                />
                <MetricCard
                    icon={'Heart'}
                    title={'Avg Engagement'}
                    value={convertNumber(data.interactions)}
                />
            </MetricGrid>

            {data.postsList && data.postsList.length
                ? (
                    <>
                        <div style={{ height: 60 }}/>
                        <LastPosts
                            {...postsProps}
                            openUrl={`https://instagram.com/${data.name}`}
                            vendor={'instagram'}
                        />
                    </>
                )
                : null}
            <div style={{ height: 60 }}/>
            <InstagramPostType {...postsTypeProps} />
            <InstagramAudience { ...audienceData } />
            <LastStories
                vendor='tiktok'
                posts={data.storiesDays}
                tabs={storiesTabs}
            />
            <FollowersGrowth {...followersProps} />
            <ProfileOverview {...interactionsProps}/>
        </div>
    )
}

Instagram.propTypes = {
    data: PropTypes.object,
}

Instagram.defaultProps = {
    data: undefined,
}

export default Instagram
