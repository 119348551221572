import PropTypes from 'prop-types'
// import Text from 'components/Text'

import { makePercentage, convertNumber } from 'lib/numbers'
import Iconly from 'components/Iconly'

const styles = {
    wrapper: {
        textAlign: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%',
        overflow: 'hidden',
        WebkitOverflowScrolling: 'touch',
    },
    item: {
        fontSize: 16,
        fontWeight: 400,
        width: 200,
        padding: '30px 15px',
        display: 'inline-block',
        color: '#A8B0C0',
        cursor: 'pointer',
        // width: 'max-content',
        whiteSpace: 'nowrap',
        borderBottom: '2px solid #EBF0F9',
        // overflowWrap: 'none',
    },
    itemActive: {
        fontWeight: 700,
        color: '#2772FF',
        background: '#F5F9FF',
        borderBottom: '2px solid #2772FF',
    },
    itemValue: {
        fontSize: 32,
        fontWeight: 800,
    },
    growthIndicator: {
        display: 'flex',
        fontWeight: 400,
        alignItems: 'center',
        justifyContent: 'center',
    },
}

const Tabs3 = ({ tabs, activeTab, setActiveTab, tabsData }) => {
    return (
        <div style={styles.wrapper}>
            {tabs.map(i => {
                let el = null
                const diff = tabsData[i.key].count - tabsData[i.key].lastCount

                return (
                    <div
                        ref={e => (el = e)}
                        key={i.key}
                        style={{
                            ...styles.item,
                            ...(activeTab === i.key ? styles.itemActive : {}),
                            // width: `${1 / tabs.length * 100}%`,
                        }}
                        onMouseEnter={() => {
                            if (activeTab === i.key) return
                            el.style.background = '#FAFAFA'
                        }}
                        onMouseLeave={() => {
                            if (activeTab === i.key) return
                            el.style.background = '#fff'
                        }}
                        onClick={() => setActiveTab(i.key)}
                    >
                        <div
                            style={styles.itemValue}
                            children={convertNumber(tabsData[i.key].count)}
                        />
                        <div
                            style={styles.label}
                            children={i.label}
                        />
                        <div style={{ height: 5 }}/>
                        <div style={{
                            ...styles.growthIndicator,
                            color: diff > 0 ? '#00985F' : '#FF274B',
                        }}>
                            <Iconly
                                bold
                                type={diff > 0 ? 'Arrow-Up' : 'Arrow-Down'}
                            />
                            {tabsData[i.key].count ? makePercentage((diff) / tabsData[i.key].count) : '0%'}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

Tabs3.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
    })),
    activeTab: PropTypes.string,
    setActiveTab: PropTypes.func,
    tabsData: PropTypes.object.isRequired,
}

Tabs3.defaultProps = {
    tabs: [],
    activeTab: null,
    setActiveTab: () => null,
}

export default Tabs3
