import PropTypes from 'prop-types'

import Icon from 'components/Iconly'
import Text from 'components/Text'

const styles = {
    wrapper: {
        background: '#fff',
        borderRadius: 15,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 600,
        boxShadow: '0px 0px 46.6167px rgba(205, 213, 231, 0.5)',
    },
    icon: {
        fontSize: 80,
    },
}

const NoAnalytics = () => {
    return (
        <div style={styles.wrapper}>
            <Icon
                style={styles.icon} type={'Activity'} bold/>
            <div style={{ height: 45 }}/>
            <Text
                size={'h2'}
                weight={'bold'}
                children={'Currently there is no analytics data to view'}
            />
            <div style={{ height: 15 }}/>
            <Text
                size={'h4'}
                weight={'normal'}
                color={'text'}
                children={'Come back later to access deep insights data'}
            />
            <div style={{ height: 15 }}/>
        </div>
    )
}

NoAnalytics.propTypes = {
    vendor: PropTypes.string,
    isMainPage: PropTypes.bool,
}

NoAnalytics.defaultProps = {
    isMainPage: false,
    vendor: '',
}

export default NoAnalytics
