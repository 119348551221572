import PropTypes from 'prop-types'
import { useState } from 'react'

import { capitalizeString } from 'lib/strings'
import { colors } from 'styles'

import Tabs from './Tabs2'
import AnalyticsTitle from './AnalyticsTitle'
import Bar from './Bar2'

const styles = {
    wrapper: {
        background: '#fff',
        // boxShadow: '0px 0px 10px rgba(205, 213, 231, 0.4)',
        // border: '1px solid #EBF0F9',
        paddingBottom: 35,
        paddingTop: 35,
        borderRadius: 15,
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
    },
    barsWrapper: {
        padding: '0px 130px',
        overflow: 'hidden',
    },
    title: {
        marginLeft: 35,
        textAlign: 'center',
    },
    barChartWrapper: {
        marginLeft: -15,
    },
    barWrapper: {
        paddingTop: 20,
    },
    barInfoWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    barName: {
        fontSize: 18,
        fontWeight: 400,
        color: colors.text,
    },
    barPercentage: {
        fontSize: 18,
        fontWeight: 500,
    },
    barDataWrapper: {
        height: 25,
        position: 'relative',
    },
    barDataActive: {
        backgroundColor: '#2772FF',
        height: 25,
        borderRadius: 10,
        width: '100%',
        position: 'absolute',

    },
    barDataBackground: {
        backgroundColor: colors.lightBlue,
        width: '100%',
        borderRadius: 10,
        height: 25,
    },
}

const InstagramPostType = ({ typesMap, tabs }) => {
    if (!typesMap || !Object.keys(typesMap).length) return null
    const [ activeTab, setActiveTab ] = useState(tabs[0] && tabs[0].key)

    return (
        <div style={styles.wrapper}>
            <div style={styles.title}>
                <AnalyticsTitle title={'Best Post Types'} />
            </div>
            <Tabs
                tabs={tabs}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
            />
            <div style={{ height: 15 }}/>
            <div style={styles.barsWrapper}>
                {Object.keys(typesMap).filter(i => i !== 'total').map(i => (
                    <Bar
                        key={i}
                        name={capitalizeString(i)}
                        total={typesMap[i][activeTab]}
                        count={typesMap[i].count}
                        perc={
                            (typesMap.total && typesMap.total[activeTab])
                                ? ((typesMap[i][activeTab] / typesMap[i].count) / typesMap.total[activeTab])
                                : 0
                        }
                    />
                ))}
            </div>
        </div>
    )
}

InstagramPostType.propTypes = {
    typesMap: PropTypes.object,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
    })),
}

InstagramPostType.defaultProps = {
    typesMap: {},
    tabs: [],
}

export default InstagramPostType
