import PropTypes from 'prop-types'
import { useState, useCallback, useRef } from 'react'
import { useSpring } from '@react-spring/web'

import { convertDate } from 'lib/dates'
import Text from 'components/Text'
import Image from 'components/Image'

import Icon from 'components/Iconly'
import { convertNumber } from 'lib/numbers'

import BarChart from './ChartBar'
import Tabs from './Tabs'
import FullPagePost from './FullPagePost'
import BoundingClientModal from 'components/BoundingClientModal'
import AnalyticsTitle from './AnalyticsTitle'

import { InstagramIcon, TiktokIcon, YoutubeIcon } from 'components/Icons'
import { capitalizeString } from 'lib/strings'

const styles = {
    wrapper: {
        background: '#fff',
        // boxShadow: '0px 0px 10px rgba(205, 213, 231, 0.4)',
        // border: '1px solid #EBF0F9',
        paddingBottom: 15,
        paddingTop: 35,
        borderRadius: 15,
        boxShadow: '0px 0px 46.6167px rgba(205, 213, 231, 0.5)',
    },
    postsImageWrapper: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-around',
        paddingLeft: 43,
        paddingRight: 5,
        overflow: 'hidden',
    },
    imageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
    },
    largePostsImgWrapper: {
        display: 'flex',
        paddingLeft: 35,
        overflowX: 'scroll',
        overFlowY: 'hidden',
        scrollBehavior: 'smooth',
    },
    largeImg: {
        marginRight: 15,
        position: 'relative',
        cursor: 'pointer',
        transition: 'opacity, transform 0.2s',
    },
    title: {
        marginLeft: 35,
        marginRight: 35,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 35,
    },
    date: {
        fontSize: 12,
        fontWeight: 700,
        marginTop: 5,
        // transform: 'rotate(90deg)',
        width: 55,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        color: '#BCC6D9',
    },
    contentItem: {
        position: 'absolute',
        zIndex: 1,
        background: 'rgba(17, 17, 24, 0.4)',
        backdropFilter: 'blur(10px)',
        borderRadius: 15,
        padding: '7.5px 15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentItemEngText: {
        color: '#FFFFFF',
        fontWeight: 600,
        fontSize: 18,
    },
    scrollWrapper: {
        position: 'relative',
    },
    scrollIndicator: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    scrollButton: {
        height: 50,
        width: 50,
        borderRadius: 50,
        background: 'linear-gradient(275.12deg, #04ECFD -46.59%, #2772FF 109.3%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        fontSize: 26,
        boxShadow: '0px 0px 30px rgba(205, 213, 231, 1)',
        cursor: 'pointer',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    icon: {
        height: 20,
        width: 20,
        marginRight: 5,
    },
}
const LastPosts = ({ posts, tabs, vendor, openUrl }) => {
    const [ activeTab, setActiveTab ] = useState(tabs[0] && tabs[0].key)
    const [ modalOpen, setModalOpen ] = useState(false)
    const [ targetPost, setTargetPost ] = useState({})
    const [ targetBounds, setTargetBounds ] = useState({
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        width: 0,
        height: 0,
    })

    const [ metricsStyleProps, api2 ] = useSpring(() => ({
        width: 0,
    }))

    const onClickHandler = useCallback((event, item) => {
        setTargetPost(item)
        const boundingClient = event.target.getBoundingClientRect()
        setTargetBounds({
            width: boundingClient.width,
            top: boundingClient.top,
            bottom: boundingClient.bottom,
            right: boundingClient.right,
            left: boundingClient.left,
            height: boundingClient.height,
        })
        setTimeout(() => setModalOpen(true), 10)
        api2.start({
            width: 400,
            delay: 250,
        })
    }, [])

    const closeModal = () => {
        api2.start({
            width: 0,
            config: {
                duration: 150,
            },
        })
        setTimeout(() => {
            setModalOpen(false)
            setTargetPost({})
        }, 500)
    }

    // handle scroll indicators
    const scrollRef = useRef(null)
    const itemWidth = vendor === 'youtube' ? 472 : 266
    const marginWidth = 15
    const itemsToScroll = vendor === 'youtube' ? 1 : 2

    const scrollRight = () => {
        scrollRef.current.scrollLeft += (itemWidth + marginWidth) * itemsToScroll
    }

    const scrollLeft = () => {
        scrollRef.current.scrollLeft += -((itemWidth + marginWidth) * itemsToScroll)
    }

    const getVendorIcon = () => {
        switch (vendor) {
            case 'instagram': return <InstagramIcon size={20}/>
            case 'youtube': return <YoutubeIcon size={20}/>
            case 'tiktok': return <TiktokIcon size={20}/>
            default: return null
        }
    }

    return (
        <div style={styles.wrapper}>
            <div style={styles.title}>
                <AnalyticsTitle title={`Last ${posts.length} posts`} />
                <div style={styles.flex} onClick={() => window.open(openUrl, '_blank').focus()}>
                    {getVendorIcon()}
                    <Text
                        size={'p'}
                        color={'primary'}
                        weight={'medium'}
                        children={`View on ${capitalizeString(vendor)}`}
                        wrapperStyle={{ marginLeft: 5 }}
                    />
                </div>
            </div>
            <BoundingClientModal
                close={closeModal}
                targetBounds={targetBounds}
                modalOpen={modalOpen}
                itemPadding={100}
                itemBorderRadius={15}
                maxHeight={window.innerHeight}
                maxWidth={window.innerWidth}
                wrapperStyles={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <FullPagePost
                    modalOpen={modalOpen}
                    animatedProps={metricsStyleProps}
                    targetPost={targetPost}
                    height={window.innerHeight - 200}
                    tabs={tabs}
                />
            </BoundingClientModal>
            <div style={styles.scrollWrapper}>
                <div
                    onClick={() => scrollLeft()}
                    style={{
                        ...styles.scrollIndicator,
                        left: -25,
                    }}>
                    <div style={styles.scrollButton}>
                        <Icon type={'Arrow-Left-2'} />
                    </div>
                </div>
                <div
                    onClick={() => scrollRight()}
                    style={{
                        ...styles.scrollIndicator,
                        right: -25,
                    }}
                >
                    <div style={styles.scrollButton}>
                        <Icon type={'Arrow-Right-2'} />
                    </div>
                </div>
                <div style={styles.largePostsImgWrapper} ref={scrollRef}>
                    {[...posts].reverse().map(item => {
                        let hoverEff = null
                        return (
                            <div
                                ref={e => (hoverEff = e)}
                                style={styles.largeImg}
                                key={item.id}
                                onMouseEnter={() => {
                                    hoverEff.style.opacity = 0.8
                                    hoverEff.style.transform = 'scale(0.95)'
                                }}
                                onMouseLeave={() => {
                                    hoverEff.style.opacity = 1
                                    hoverEff.style.transform = 'scale(1)'
                                }}
                                onClick={(event) => onClickHandler(event, item)}
                            >
                                <div style={{
                                    ...styles.contentItem,
                                    bottom: 15,
                                    right: 15,
                                    display: item.impressions || item.views ? 'flex' : 'none',
                                }}>
                                    <Icon
                                        type={'Show'}
                                        bold
                                        style={{
                                            color: '#FFFFFF',
                                            fontSize: 15,
                                        }}
                                    />
                                    <div style={{ width: 5 }}/>
                                    <div style={styles.contentItemEngText}>
                                        {vendor === 'instagram'
                                            ? convertNumber(item.impressions)
                                            : convertNumber(item.views)
                                        }
                                    </div>
                                </div>
                                <div style={{
                                    ...styles.contentItem,
                                    bottom: 15,
                                    left: 15,
                                }}>
                                    <Icon
                                        type={'Heart'}
                                        bold
                                        style={{
                                            color: '#FFFFFF',
                                            fontSize: 18,
                                        }}
                                    />
                                    <div style={{ width: 5 }}/>
                                    <div style={styles.contentItemEngText}>{convertNumber(item.likes)}</div>
                                </div>
                                <Image
                                    height={vendor === 'tiktok'
                                        ? 472
                                        : 266
                                    }
                                    width={
                                        vendor === 'youtube'
                                            ? 472
                                            : 266
                                    }
                                    src={item.pic}
                                    borderRadius={15}
                                    fallbackType={'contentV1'}
                                    objectFit={'cover'}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
            <div style={{ height: 40 }}/>
            <Tabs
                tabs={tabs}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
            />
            <div style={{ height: 30 }}/>
            <BarChart data={posts} dataKey={activeTab} hideDates/>
            <div style={styles.postsImageWrapper}>
                {posts.map(item => {
                    let hoverEff1 = null
                    return (
                        <div
                            key={item.id}
                            ref={e => (hoverEff1 = e)}
                            onMouseEnter={() => {
                                hoverEff1.style.opacity = 0.8
                                hoverEff1.style.transform = 'scale(0.95)'
                            }}
                            onMouseLeave={() => {
                                hoverEff1.style.opacity = 1
                                hoverEff1.style.transform = 'scale(1)'
                            }}
                            onClick={(event) => onClickHandler(event, item)}
                            style={{
                                ...styles.imageWrapper,
                                height: vendor === 'tiktok' ? 130 : 90,
                            }}>
                            <Image
                                height={ vendor === 'tiktok' ? 96 : 54}
                                width={54}
                                src={item.pic}
                                borderRadius={5}
                                objectFit={'cover'}
                                fallbackType={'contentV1'}
                            />
                            <Text
                                size={'p2'}
                                color={'text'}
                                weight={'medium'}
                                children={convertDate(item.date)}
                                wrapperStyle={{ marginTop: 7.5 }}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

LastPosts.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.shape({
        views: PropTypes.number,
        comments: PropTypes.number,
        likes: PropTypes.number,
    })),
    tabs: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
    })),
    openUrl: PropTypes.string,
    vendor: PropTypes.string.isRequired,
}

LastPosts.defaultProps = {
    posts: [],
    tabs: [],
    openUrl: '',
}

export default LastPosts
