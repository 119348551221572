export const styles = {
    wrapper: {
    },
    content: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        height: '100%',
        objectFit: 'cover',
    },
    rightArea: {
        position: 'absolute',
        width: '65%',
        top: 0,
        right: 0,
        height: '75%',
        zIndex: 4,
    },
    leftArea: {
        position: 'absolute',
        width: '35%',
        top: 0,
        left: 0,
        height: '75%',
        zIndex: 4,
    },
    impressions: {
        position: 'absolute',
        bottom: 30,
        left: 15,
        fontSize: 14,
        zIndex: 5,
        color: '#fff',
        background: 'rgba(17, 17, 24, 0.4)',
        backdropFilter: 'blur(10px)',
        borderRadius: 10,
        padding: '7.5px 15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        willChange: 'opacity',
    },
    reach: {
        position: 'absolute',
        bottom: 30,
        right: 15,
        fontSize: 14,
        zIndex: 5,
        color: '#fff',
        textAlign: 'right',
        background: 'rgba(17, 17, 24, 0.4)',
        backdropFilter: 'blur(10px)',
        borderRadius: 10,
        padding: '7.5px 15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 600,
        willChange: 'opacity',
    },
    timeStamp: {
        position: 'absolute',
        top: 30,
        right: 15,
        fontSize: 14,
        zIndex: 5,
        color: '#fff',
        background: 'rgba(17, 17, 24, 0.4)',
        backdropFilter: 'blur(10px)',
        borderRadius: 10,
        padding: '7.5px 15px',
        willChange: 'opacity',
    },
    stepperWrapper: {
        display: 'flex',
        position: 'absolute',
        zIndex: 4,
        left: 10,
        right: 10,
        top: 10,
    },
    stepperItem: {
        height: 2.5,
        background: '#fff',
        width: '100%',
        borderRadius: 5,
        marginLeft: 2.5,
        marginRight: 2.5,
    },
    topViewGradient: {
        // borderTopLeftRadius: 10,
        // borderTopRightRadius: 10,
        position: 'absolute',
        height: 30,
        left: 0,
        right: 0,
        top: 0,
        zIndex: 3,
        background: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.50) 80%)',
    },
    mediaFailTitle: {
        fontSize: 16,
        fontWeight: 400,
        textAlign: 'center',
        color: '#fff',
    },
    mediaFailWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
}
