import PropTypes from 'prop-types'

const styles = {
    wrapper: {
        textAlign: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%',
        overflowX: 'scroll',
        WebkitOverflowScrolling: 'touch',
        borderBottom: '2px solid #EBF0F9',
    },
    item: {
        fontSize: 18,
        fontWeight: 400,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        padding: '10px 15px',
        display: 'inline-block',
        color: '#A8B0C0',
        cursor: 'pointer',
        // width: 'max-content',
        whiteSpace: 'nowrap',
        // overflowWrap: 'none',
    },
    itemActive: {
        fontWeight: 700,
        borderBottom: '2px solid #2772FF',
        color: '#2772FF',
    },
}

const Tabs = ({ tabs, activeTab, setActiveTab }) => {
    return (
        <div style={styles.wrapper}>
            {tabs.map(i => {
                let el = null
                return (
                    <div
                        ref={e => (el = e)}
                        key={i.key}
                        style={{
                            ...styles.item,
                            width: 800 / tabs.length,
                            ...(activeTab === i.key ? styles.itemActive : {}),
                            // width: `${1 / tabs.length * 100}%`,
                        }}
                        onMouseEnter={() => {
                            el.style.backgroundColor = '#FAFAFA'
                        }}
                        onMouseLeave={() => {
                            el.style.backgroundColor = '#fff'
                        }}
                        onClick={() => setActiveTab(i.key)}
                        children={i.label}
                    />
                )
            })}
        </div>
    )
}

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
    })),
    activeTab: PropTypes.string,
    setActiveTab: PropTypes.func,
}

Tabs.defaultProps = {
    tabs: [],
    activeTab: null,
    setActiveTab: () => null,
}

export default Tabs
