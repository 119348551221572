
import { Switch, Route } from 'react-router-dom'

import { HomePage, ProfilePage } from './pages'

const App = () => {
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={'/:username'}
                    component={ProfilePage}
                />
                <Route
                    path={'/'}
                    component={HomePage}
                />
            </Switch>
        </>
    )
}

export default App
