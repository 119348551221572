import PropTypes from 'prop-types'

import Icon from 'components/Iconly'
import Text from 'components/Text'

const styles = {
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        // border: '1px solid #DAE6FD',
        background: '#fff',
        // boxShadow: '0px 0px 10px rgba(205, 213, 231, 0.4)',
        borderRadius: 10,
        padding: '15px 0px 15px 15px',
        width: '100%',
    },
    iconWrapper: {
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 35,
        width: 35,
        fontSize: 16,
    },
}

const MetricCard = ({
    label,
    value,
    icon,
    iconBg,
    iconColor,
}) => {
    return (
        <div style={styles.wrapper}>
            <div style={{
                ...styles.iconWrapper,
                backgroundColor: iconBg && iconBg.length ? iconBg : '#ECFCFF',
                color: iconColor && iconColor.length ? iconColor : '#68ADBA',
            }}>
                <Icon type={icon} bold/>
            </div>
            <div style={{ width: 10 }}/>
            <div>
                <Text
                    children={label}
                    color={'text'}
                    size={'p2'}
                />
                <Text
                    children={value}
                    color={'black'}
                    weight={'bold'}
                    size={'h4'}
                />
            </div>
        </div>
    )
}

MetricCard.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    icon: PropTypes.string.isRequired,
    iconBg: PropTypes.string.isRequired,
    iconColor: PropTypes.string.isRequired,
}

export default MetricCard
