import PropTypes from 'prop-types'
// import Text from 'components/Text'

import { makePercentage, convertNumber } from 'lib/numbers'
import Iconly from 'components/Iconly'
import { colors } from 'styles'

const styles = {
    wrapper: {
        textAlign: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%',
        overflow: 'scroll',
        WebkitOverflowScrolling: 'touch',
    },
    item: {
        fontSize: 13,
        fontWeight: 400,
        marginTop: 15,
        marginLeft: 15,
        marginRight: 15,
        display: 'inline-block',
        color: colors.black,
        cursor: 'pointer',
        textAlign: 'left',
        // width: 'max-content',
        whiteSpace: 'nowrap',
        // borderBottom: '2px solid #EBF0F9',
        // overflowWrap: 'none',
    },
    itemActive: {
        color: colors.black,
        // background: '#F5F9FF',
    },
    itemValue: {
        fontSize: 24,
        fontWeight: 500,
    },
    growthIndicator: {
        display: 'flex',
        fontWeight: 500,
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontSize: 11,
    },
    activeLine: {
        width: '100%',
        marginTop: 10,
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        height: 3,
        background: '#2772FF',
    },
}

const Tabs3 = ({ tabs, activeTab, setActiveTab, tabsData }) => {
    return (
        <div style={styles.wrapper}>
            {tabs.map(i => {
                const diff = tabsData[i.key].count - tabsData[i.key].lastCount

                return (
                    <div
                        key={i.key}
                        style={{
                            ...styles.item,
                            ...(activeTab === i.key ? styles.itemActive : {}),
                            // width: `${1 / tabs.length * 100}%`,
                        }}
                        onClick={() => setActiveTab(i.key)}
                    >
                        <div
                            style={styles.label}
                            children={i.label}
                        />
                        <div
                            style={styles.itemValue}
                            children={convertNumber(tabsData[i.key].count)}
                        />
                        <div style={{ height: 5 }}/>
                        <div style={{
                            ...styles.growthIndicator,
                            color: diff > 0 ? '#00985F' : '#FF274B',
                        }}>
                            <Iconly
                                bold
                                type={diff > 0 ? 'Arrow-Up' : 'Arrow-Down'}
                            />
                            {tabsData[i.key].count ? makePercentage((diff) / tabsData[i.key].count) : '0%'}
                        </div>
                        {activeTab === i.key ? <div style={styles.activeLine} /> : null}
                    </div>
                )
            })}
        </div>
    )
}

Tabs3.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
    })),
    activeTab: PropTypes.string,
    setActiveTab: PropTypes.func,
    tabsData: PropTypes.object.isRequired,
}

Tabs3.defaultProps = {
    tabs: [],
    activeTab: null,
    setActiveTab: () => null,
}

export default Tabs3
