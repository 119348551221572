import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSpring, animated } from '@react-spring/web'
import logo from 'resources/images/logo-black.png'

const styles = {
    wrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 5,
        background: '#fff',
        height: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px -4px 35px rgba(205, 213, 231, 0.4)',
    },
    absoluteItem: {
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        textAlign: 'center',
        position: 'absolute',
    },
    profilePic: {
        height: 25,
        width: 25,
        borderRadius: 50,
        objectFit: 'cover',
    },
}

const Header = ({ profilePic, name }) => {
    const [ showProfile, setShowProfile ] = useState(false)

    window.addEventListener('scroll', (event) => {
        if (window.scrollY > 250 && !showProfile) {
            setShowProfile(true)
        }
        if (window.scrollY < 200 && showProfile) {
            setShowProfile(false)
        }
    })

    const imgSpring = useSpring({
        opacity: showProfile ? 0 : 1,
        ...styles.absoluteItem,
        height: 25,
        config: {
            duration: 100,
        },
    })

    const profileSpring = useSpring({
        opacity: showProfile ? 1 : 0,
        ...styles.absoluteItem,
        config: {
            duration: 100,
        },
    })

    return (
        <div style={{
            ...styles.wrapper,
            height: showProfile ? 50 : 60,
            transition: 'height 0.2s',
        }}>
            <animated.img
                src={logo}
                alt={'mysocial'}
                style={{
                    ...imgSpring,
                    willChange: 'opacity',
                }}
            />
            <animated.div style={{
                ...profileSpring,
                willChange: 'opacity',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 500,
            }}>
                <img src={profilePic} style={styles.profilePic}/>
                <div style={{ width: 10 }}/>
                <span> {name}</span>
            </animated.div>
        </div>
    )
}

Header.propTypes = {
    channels: PropTypes.array,
    activeChannel: PropTypes.object,
    setActiveChannel: PropTypes.func,
    profilePic: PropTypes.string,
    name: PropTypes.string,
}

Header.defaultProps = {
    channels: [],
    activeChannel: {},
    setActiveChannel: () => null,
    profilePic: '',
    name: '',
}

export default Header
