
export const initialState = {
    profile: null,
    analytics: {},
}

/**
 * Actions
 */

export const SET_DATA = 'setData@profile'
export const SET_ANALYTICS = 'setAnalytics@profile'

export const setData = (values) => ({
    type: SET_DATA,
    payload: { values },
})

export const setAnalytics = (key, data) => ({
    type: SET_ANALYTICS,
    payload: { key, data },
})

/**
 * Handlers
 */

export const actionHandlers = {
    '@reset': () => ({ ...initialState }),
    [SET_DATA]: (state, { payload }) => ({
        ...state,
        ...payload.values,
    }),
    [SET_ANALYTICS]: (state, { payload }) => ({
        ...state,
        analytics: {
            ...state.analytics,
            [payload.key]: payload.data,
        },
    }),
}

export const reducer = (state = initialState, action) => {
    const handler = actionHandlers[action.type]
    return handler ? handler(state, action) : state
}

export default reducer
