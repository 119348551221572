import PropTypes from 'prop-types'
import { useState, useCallback } from 'react'
import { useSpring } from '@react-spring/web'

import { convertDate } from 'lib/dates'
import Text from 'components/Text'
import Image from 'components/Image'

import Icon from 'components/Iconly'
import BoundingClientModal from 'components/BoundingClientModal'
import { convertNumber } from 'lib/numbers'
import { InstagramIcon, TiktokIcon, YoutubeIcon } from 'components/Icons'
import { capitalizeString } from 'lib/strings'

import BarChart from './ChartBar'
import Tabs from './Tabs'
import FullPagePost from './FullPagePost'

const styles = {
    wrapper: {
        background: '#fff',
        // boxShadow: '0px 0px 10px rgba(205, 213, 231, 0.4)',
        // border: '1px solid #EBF0F9',
        paddingBottom: 15,
        paddingTop: 15,
        borderRadius: 15,
        boxShadow: '0px 0px 25px rgba(205, 213, 231, 0.3)',
    },
    postsImageWrapper: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-around',
        paddingLeft: 44,
        paddingRight: 5,
        overflow: 'hidden',
    },
    imageWrapper: {
        height: 80,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    largePostsImgWrapper: {
        display: 'flex',
        paddingLeft: 15,
        overflowX: 'scroll',
        overflowY: 'hidden',
        WebkitOverflowScrolling: 'touch',
    },
    largeImg: {
        marginRight: 10,
        position: 'relative',
    },
    title: {
        marginLeft: 15,
        marginRight: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    date: {
        fontSize: 12,
        marginTop: 5,
        transform: 'rotate(90deg)',
        width: 0,
        whiteSpace: 'nowrap',
        color: '#BCC6D9',
    },
    contentItem: {
        position: 'absolute',
        zIndex: 1,
        background: 'rgba(17, 17, 24, 0.4)',
        backdropFilter: 'blur(10px)',
        borderRadius: 10,
        padding: '5px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentItemEngText: {
        color: '#FFFFFF',
        fontWeight: 600,
        fontSize: 14,
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
}
const LastPosts = ({ posts, tabs, vendor, openUrl }) => {
    if (!posts.length) return null
    const [ activeTab, setActiveTab ] = useState(tabs[0] && tabs[0].key)
    const [ modalOpen, setModalOpen ] = useState(false)
    const [ targetPost, setTargetPost ] = useState({})
    const [ targetBounds, setTargetBounds ] = useState({
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        width: 0,
        height: 0,
    })

    const [ metricsStyleProps, api2 ] = useSpring(() => ({
        height: 0,
    }))

    const onClickHandler = useCallback((event, item) => {
        setTargetPost(item)
        const boundingClient = event.target.getBoundingClientRect()
        setTargetBounds({
            width: boundingClient.width,
            top: boundingClient.top,
            bottom: boundingClient.bottom,
            right: boundingClient.right,
            left: boundingClient.left,
            height: boundingClient.height,
        })
        setTimeout(() => setModalOpen(true), 10)
        api2.start({
            height: 300,
            delay: 250,
        })
    }, [])

    const closeModal = () => {
        api2.start({
            height: 0,
            config: {
                duration: 150,
            },
        })
        setTimeout(() => {
            setModalOpen(false)
            setTargetPost({})
        }, 500)
    }

    const getVendorIcon = () => {
        switch (vendor) {
            case 'instagram': return <InstagramIcon size={15}/>
            case 'youtube': return <YoutubeIcon size={15}/>
            case 'tiktok': return <TiktokIcon size={15}/>
            default: return null
        }
    }

    return (
        <div style={styles.wrapper}>
            <div style={styles.title}>
                <Text
                    children={`Last ${posts.length} posts`}
                    size={'h6'}
                    weight={'bold'}
                />
                <div style={styles.flex} onClick={() => window.open(openUrl, '_blank').focus()}>
                    {getVendorIcon()}
                    <Text
                        size={'p2'}
                        color={'primary'}
                        weight={'medium'}
                        children={`View on ${capitalizeString(vendor)}`}
                        wrapperStyle={{ marginLeft: 5 }}
                    />
                </div>
            </div>
            <div style={{ height: 30 }}/>
            <BoundingClientModal
                close={closeModal}
                targetBounds={targetBounds}
                modalOpen={modalOpen}
                itemBorderRadius={10}
                maxHeight={window.innerHeight}
                maxWidth={window.innerWidth}
            >
                <FullPagePost
                    modalOpen={modalOpen}
                    animatedProps={metricsStyleProps}
                    targetPost={targetPost}
                    tabs={tabs}
                    vendor={vendor}
                />
            </BoundingClientModal>
            <div style={styles.largePostsImgWrapper}>
                {[...posts].reverse().map(item => {
                    return (
                        <div
                            style={styles.largeImg}
                            key={item.id}
                            onClick={(event) => onClickHandler(event, item)}
                        >
                            <div style={{
                                ...styles.contentItem,
                                bottom: 10,
                                right: 10,
                                display: item.impressions || item.views ? 'flex' : 'none',
                            }}>
                                <Icon
                                    type={'Show'}
                                    bold
                                    style={{
                                        color: '#FFFFFF',
                                        fontSize: 15,
                                    }}
                                />
                                <div style={{ width: 5 }}/>
                                <div style={styles.contentItemEngText}>
                                    {vendor === 'instagram'
                                        ? convertNumber(item.impressions)
                                        : convertNumber(item.views)
                                    }
                                </div>
                            </div>
                            <div style={{
                                ...styles.contentItem,
                                bottom: 10,
                                left: 10,
                            }}>
                                <Icon
                                    type={'Heart'}
                                    bold
                                    style={{
                                        color: '#FFFFFF',
                                        fontSize: 15,
                                    }}
                                />
                                <div style={{ width: 5 }}/>
                                <div style={styles.contentItemEngText}>{convertNumber(item.likes)}</div>
                            </div>
                            <Image
                                height={vendor === 'tiktok'
                                    ? '88.88vw'
                                    : '50vw'
                                }
                                width={
                                    vendor === 'youtube'
                                        ? '88.88vw'
                                        : '50vw'
                                }
                                src={item.pic}
                                fallbackType={vendor === 'tiktok' ? 'storyFallback' : 'contentV1'}
                                borderRadius={10}
                                objectFit={'cover'}
                            />
                        </div>
                    )
                })}
            </div>
            <div style={{ height: 30 }}/>
            <Tabs
                tabs={tabs}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                justifyContent={tabs.length > 2 ? 'space-between' : 'flex-start'}
            />
            <div style={{ height: 30 }}/>
            <BarChart data={posts} dataKey={activeTab} hideDate/>
            <div style={styles.postsImageWrapper}>
                {posts.map(item => {
                    return (
                        <div
                            key={item.id}
                            onClick={(event) => onClickHandler(event, item)}
                            style={{
                                ...styles.imageWrapper,
                            }}>
                            <Image
                                height={29}
                                width={29}
                                src={item.pic}
                                borderRadius={5}
                                objectFit={'cover'}
                                fallbackType={vendor === 'tiktok' ? 'storyFallback' : 'contentV1'}
                            />
                            <span
                                style={styles.date}
                                children={convertDate(item.date)}/>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

LastPosts.propTypes = {
    posts: PropTypes.arrayOf(PropTypes.shape({
        views: PropTypes.number,
        comments: PropTypes.number,
        likes: PropTypes.number,
    })),
    tabs: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
    })),
    vendor: PropTypes.string.isRequired,
    openUrl: PropTypes.string,
}

LastPosts.defaultProps = {
    posts: [],
    tabs: [],
    openUrl: '',
}

export default LastPosts
